import React, { memo } from "react";
import { PreloadItemStyled } from "./style";
import Image from "components/Image";
import { size } from "responsive";
import { useMobile } from "hooks";

import PreloadSrc from "icons/preload.gif";
import PreloadMobileSrc from "icons/preload_m.gif";

const PreloadItem = () => {
  const mobile = useMobile(size.xs);

  return (
    <PreloadItemStyled>
      {mobile ? (
        <Image src={PreloadMobileSrc} alt="mobile preload" />
      ) : (
        <Image src={PreloadSrc} alt="preload" />
      )}
    </PreloadItemStyled>
  );
};

export default memo(PreloadItem);
