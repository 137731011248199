import React, { memo } from "react";
import { SuccessPaymentModalStyled, Title, Text } from "./style";
import Modal from "../../../Modal";
import CloseButton from "../../components/CloseButton";
import { useTranslation } from "react-i18next";

const SuccessPaymentModal = ({ open, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose} width={600}>
      <SuccessPaymentModalStyled>
        <CloseButton onClick={onClose} />
        <Title>{t("Thanks for Your Support!")}</Title>
        <Text>{t("Check your email for the license details.")}</Text>
      </SuccessPaymentModalStyled>
    </Modal>
  );
};

export default memo(SuccessPaymentModal);
