import React, { memo } from "react";
import { SocialIconStyled } from "./style";
import Icon from "components/Icon";

const SocialIcon = ({ src, alt, ...props }) => {
  return (
    <SocialIconStyled {...props}>
      <Icon src={src} alt={alt} />
    </SocialIconStyled>
  );
};

export default memo(SocialIcon);
