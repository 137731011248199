import styled from "styled-components";
import { device } from "responsive";

export const ArrowsStyled = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
  min-width: 40px;
  justify-content: space-between;
`;

export const Arrow = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: whitesmoke;
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:hover {
    color: #bfbfbf;
  }

  @media ${device.xs} {
    cursor: inherit;
  }
`;
