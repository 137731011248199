import styled from "styled-components";
import { device } from "responsive";

export const FavIconStyled = styled.div`
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  margin-left: 15px;
  cursor: pointer;

  @media ${device.xs} {
    margin-left: 10px;
  }
`;
