import React, { memo, useState, useCallback } from "react";
import { ShareIconStyled } from "./style";
import Icon from "components/Icon";
import Slide from "./components/Slide";

import IconSrc from "icons/share.svg";
import HoverIconSrc from "icons/share_w.svg";

const ShareIcon = ({ trackId, title }) => {
  const [hover, setHover] = useState(false);

  const onMouseEnter = useCallback(() => {
    setHover(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setHover(false);
  }, []);

  return (
    <ShareIconStyled onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Slide open={hover} trackId={trackId} title={title} />
      <Icon src={hover ? HoverIconSrc : IconSrc} alt="share" />
    </ShareIconStyled>
  );
};

export default memo(ShareIcon);
