import styled from "styled-components";
import { device } from "responsive";

export const PreloadItemStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  border: 1px solid #d9d9d9;
  height: 95px;
  box-sizing: border-box;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  @media ${device.xxs} {
    height: 85px;
  }
`;
