import React, { memo } from "react";
import { OpenIconStyled } from "./style";
import Icon from "components/Icon";

import PlusIcon from "icons/plus.svg";
import MinusIcon from "icons/minus.svg";

const OpenIcon = ({ open, onClick }) => {
  return (
    <OpenIconStyled open={open} onClick={onClick}>
      {open ? (
        <Icon src={MinusIcon} alt="close" />
      ) : (
        <Icon src={PlusIcon} alt="open" />
      )}
    </OpenIconStyled>
  );
};

export default memo(OpenIcon);
