import styled from "styled-components";
import Icon from "components/Icon";

export const ShowAllStyled = styled.div`
  display: inline-flex;
  justify-content: space-between;
  border: 1px solid #d9d9d9;
  border-radius: 7px;
  padding: 5px 10px;
  margin: 10px;
  font-size: 14px;
  cursor: pointer;
  color: #666666;
  max-width: 80px;
  width: 100%;
`;

export const ShowAllIcon = styled(Icon)`
  margin-right: 10px;
`;

export const CloseIcon = styled(Icon)`
  width: 10px;
  margin-right: 10px;
`;
