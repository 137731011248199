import React, { memo } from "react";
import { Wrapper, Field, InputField, FieldIcon, Error } from "./style";

const Input = ({
  icon,
  onChange,
  width,
  placeholder,
  errors,
  onPressEnter,
  value,
  alt,
  ...props
}) => {
  const onPressKey = (e) => {
    if (e.keyCode === 13 && onPressEnter) {
      onPressEnter();
    }
  };

  return (
    <Wrapper>
      <Field width={width}>
        {icon && <FieldIcon src={icon} alt={alt} />}
        <InputField
          onKeyDown={onPressKey}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          {...props}
        />
      </Field>
      {errors &&
        !!errors.length &&
        errors.map((error, i) => <Error key={i}>{error}</Error>)}
    </Wrapper>
  );
};

export default memo(Input);
