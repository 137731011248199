import styled from "styled-components";

export const TagStyled = styled.div`
  display: inline;
  margin-right: 5px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
