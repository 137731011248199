import React, { memo, useCallback, useState } from "react";
import { Date, PrivacyContainer, Text, Paragraph } from "./style";
import Banner from "components/Banner";
import Anchor from "./components/Anchor";
import Title from "./components/Title";
import { useTranslation, Trans } from "react-i18next";
import animateScrollTo from "animated-scroll-to";
import Meta from "components/Meta";

import LogoSrc from "images/logo.png";

const PrivacyPage = () => {
  const [itemPositions, setItemPositions] = useState([]);
  const { t } = useTranslation();

  const onClickAnchor = useCallback(
    (item) => {
      animateScrollTo(itemPositions[item], {
        verticalOffset: 0,
        maxDuration: 1000,
      });
    },
    [itemPositions]
  );

  const onPositionUpdateHandler = useCallback(
    (item, position) => {
      setItemPositions((itemPositions) => {
        const positions = [...itemPositions];
        positions[item] = position;
        return positions;
      });
    },
    [itemPositions]
  );

  return (
    <>
      <Meta>
        <title>Download Free Music for Video | Slash Audio</title>
        <meta
          name="description"
          content="Free downloads of royalty free music for any project, including video, YouTube, TV, film, podcasts, and more. Commercial licenses available."
        />
        <meta
          property="og:title"
          content="Download Free Music for Video | Slash Audio"
        />
        <meta property="og:url" content={LogoSrc} />
      </Meta>

      <Banner title={t("PRIVACY POLICY")} />
      <PrivacyContainer>
        <Date>{t("Effective Date")}: 8/1/2020</Date>
        <Date>{t("Last Updated")}: 9/01/2020</Date>
        <Paragraph>
          <Text>
            <Trans>
              We have prepared this privacy policy ('Privacy Policy' or
              'Policy') to explain to you how we collect, use, and share
              information and Personal Data (as defined under applicable law) we
              obtain through your use of the Internet sites, applications and
              online services ('Services') that are operated by, controlled by
              or affiliated with Slash Audio, including without limitation
              www.slashaudio.com (collectively, “Slash Audio”, 'we,' 'us' or
              'our'). This Privacy Policy only covers information collected
              through the Services and via direct communications between you and
              Slash Audio, and does not cover any information collected at any
              other website, application or otherwise by us (unless specifically
              stated), including when you call us, write to us, or communicate
              with us in any manner other than through the Services. By using
              the Services, you consent to such collection, use and sharing of
              your information and Personal Data and agree to the terms of this
              Privacy Policy.
            </Trans>
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            <Trans>
              We will only process your Personal Data in accordance with
              applicable data protection and privacy laws. For the purposes of
              UK and EU data protection legislation, the data controller is
              Slash Audio, Inc. of 12400 Ventura Blvd #122 Studio City,
              California 91604 USA.
            </Trans>
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>{t("TABLE OF CONTENTS")}</Text>
        </Paragraph>

        <Anchor item="1" onClick={onClickAnchor}>
          {t("INFORMATION WE AUTOMATICALLY COLLECT")}
        </Anchor>
        <Anchor item="2" onClick={onClickAnchor}>
          {t("COOKIES/TRACKING TECHNOLOGIES")}
        </Anchor>
        <Anchor item="3" onClick={onClickAnchor}>
          {t("INFORMATION YOU CHOOSE TO SUBMIT")}
        </Anchor>
        <Anchor item="4" onClick={onClickAnchor}>
          {t("INFORMATION WE RECEIVE FROM OTHER SOURCES")}
        </Anchor>
        <Anchor item="5" onClick={onClickAnchor}>
          {t("INFORMATION USE")}
        </Anchor>
        <Anchor item="6" onClick={onClickAnchor}>
          {t("SOCIAL NETWORK AND PLATFORM INTEGRATION")}
        </Anchor>
        <Anchor item="7" onClick={onClickAnchor}>
          {t("OUR INFORMATION SHARING PRACTICES")}
        </Anchor>
        <Anchor item="8" onClick={onClickAnchor}>
          {t("ANONYMOUS DATA")}
        </Anchor>
        <Anchor item="9" onClick={onClickAnchor}>
          {t("PUBLIC INFORMATION")}
        </Anchor>
        <Anchor item="10" onClick={onClickAnchor}>
          {t("USERS OUTSIDE OF THE UNITED STATES AND CONSENT TO TRANSFER")}
        </Anchor>
        <Anchor item="11" onClick={onClickAnchor}>
          {t(
            "IMPORTANT INFORMATION FOR CALIFORNIA RESIDENTS - YOUR CALIFORNIA PRIVACY"
          )}
        </Anchor>
        <Anchor item="11" onClick={onClickAnchor}>
          {t("RIGHTS")}
        </Anchor>
        <Anchor item="12" onClick={onClickAnchor}>
          {t("HOW WE RESPOND TO DO NOT TRACK SIGNALS")}
        </Anchor>
        <Anchor item="13" onClick={onClickAnchor}>
          {t("ADVERTISING")}
        </Anchor>
        <Anchor item="14" onClick={onClickAnchor}>
          {t("CHOICE/OPT-OUT OF COMMUNICATIONS")}
        </Anchor>
        <Anchor item="15" onClick={onClickAnchor}>
          {t("RETAINING, MODIFYING AND DELETING YOUR PERSONAL DATA")}
        </Anchor>
        <Anchor item="16" onClick={onClickAnchor}>
          {t("EU DATA SUBJECT RIGHTS")}
        </Anchor>
        <Anchor item="17" onClick={onClickAnchor}>
          {t("SECURITY")}
        </Anchor>
        <Anchor item="18" onClick={onClickAnchor}>
          {t("LINKS")}
        </Anchor>
        <Anchor item="19" onClick={onClickAnchor}>
          {t("CHILDREN'S POLICY")}
        </Anchor>
        <Anchor item="20" onClick={onClickAnchor}>
          {t("SENSITIVE PERSONAL DATA")}
        </Anchor>
        <Anchor item="21" onClick={onClickAnchor}>
          {t("CHANGES")}
        </Anchor>
        <Anchor item="22" onClick={onClickAnchor}>
          {t("CONTACT US")}
        </Anchor>

        <Title onPositionUpdate={onPositionUpdateHandler} item="1">
          1. {t("Information We Automatically Collect")}
        </Title>
        <Paragraph>
          <Text>
            <Trans>
              We and our third-party service providers (including any
              third-party content, advertising, and analytics providers)
              automatically collect certain information from your device or web
              browser when you interact with the Services to help us understand
              how our users use the Services and to target advertising to you
              (which we will refer to in this Privacy Policy collectively as
              'Usage Data'). For example, each time you visit the Services we
              and our third-party service providers automatically collect your
              IP address, mobile device identifier or other unique identifier,
              browser and computer type, access time, the Web page you came
              from, the URL you go to next, the Web page(s) that you access
              during your visit and your interaction with content or advertising
              on the Services.
            </Trans>
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            <Trans>
              We and our third-party service providers use such Usage Data for a
              variety of purposes including to diagnose problems with our
              servers and software, to administer the Services, to gather
              demographic information and to target advertising to you on the
              Services and elsewhere online. Accordingly, our third-party
              advertising networks and ad servers will also provide us with
              information, including reports that will tell us how many ads were
              presented and clicked on the Services in a manner that does not
              identify personally any specific individual. The Usage Data we
              collect is generally non-identifying, but if we associate it with
              you as a specific and identifiable person, we will treat it as
              Personal Data.
            </Trans>
          </Text>
        </Paragraph>

        <Title onPositionUpdate={onPositionUpdateHandler} item="2">
          2. {t("Cookies/Tracking Technologies")}
        </Title>
        <Text>
          <Trans>
            We use tracking technologies, such as cookies, local storage, and
            pixel tags.
          </Trans>
        </Text>
        <Paragraph>
          <Text>
            <Trans>Cookies and Local Storage</Trans>
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            <Trans>
              Cookies and local storage may be set and accessed on your
              computer. Upon your first visit to the Services, a cookie or local
              storage will be sent to your computer that uniquely identifies
              your browser. 'Cookies' and local storage are small files
              containing a string of characters that is sent to your computer's
              browser and stored on your device when you visit a website. Many
              major Web services use cookies to provide useful features for
              their users. Each Web site can send its own cookie to your
              browser. Most browsers are initially set up to accept cookies. You
              can reset your browser to refuse all cookies or to indicate when a
              cookie is being sent; however, if you reject cookies, you will not
              be able to sign in to the Services or take full advantage of our
              Services. Additionally, if you clear all cookies on your browser
              at any point after setting your browser to refuse all cookies or
              indicate when a cookie is being sent, you will have to again reset
              your browser to refuse all cookies or indicate when a cookie is
              being sent.
            </Trans>
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            <Trans>
              Our Services use the following types of cookies for the purposes
              set out below.
            </Trans>
          </Text>
        </Paragraph>
        <Text>
          <Trans>
            (a) Analytics and Performance Cookies. These cookies are used to
            collect information about traffic to our Services and how users use
            our Services. The information gathered does not identify any
            individual visitor. The information is aggregated and therefore
            anonymous. It includes the number of visitors to our Services, the
            websites that referred them to our Services, the pages that they
            visited on our Services, what time of day they visited our Services,
            whether they have visited our Services before, and other similar
            information. We use this information to help operate our Services
            more efficiently, to gather broad demographic information and to
            monitor the level of activity on our Services. We use Google
            Analytics for this purpose. Google Analytics uses its own cookies.
            It is only used to improve how our Services works. You can find out
            more information about Google Analytics cookies here
          </Trans>{" "}
          <a
            href="https://developers.google.com/analytics/resources/concepts/gaConceptsCookies"
            target="_blank"
          >
            https://developers.google.com/analytics/resources/concepts/gaConceptsCookies
          </a>
          <Trans>
            You can find out more about how Google protects your data here
          </Trans>{" "}
          <a href="www.google.com/analytics/learn/privacy.htm" target="_blank">
            www.google.com/analytics/learn/privacy.htm
          </a>
          .
          <Trans>
            You can prevent the use of Google Analytics relating to your use of
            our Services by downloading and installing the browser plugin
            available via this link
          </Trans>{" "}
          <a
            href="http://tools.google.com/dlpage/gaoptout?hl=en-GB"
            target="_blank"
          >
            http://tools.google.com/dlpage/gaoptout?hl=en-GB
          </a>
          .
        </Text>
        <Text>
          <Trans>
            (b) Essential Cookies. These cookies are essential to provide you
            with services available through our Services and to enable you to
            use its features. For example, they allow you to log in to secure
            areas of our Services and help the content of the pages you request
            load quickly. Without these cookies, the services that you have
            asked for cannot be provided, and we only use these cookies to
            provide you with those services.
          </Trans>
        </Text>
        <Text>
          <Trans>
            (c) Functionality Cookies. These cookies allow our Services to
            remember choices you make when you use our Services, such as
            remembering your language preferences, remembering your login
            details, remembering which polls you have voted in and in some
            cases, to show you poll results, and remembering the changes you
            make to other parts of our Services which you can customize. The
            purpose of these cookies is to provide you with a more personal
            experience and to avoid you having to re-enter your preferences
            every time you visit our Services.
          </Trans>
        </Text>
        <Text>
          <Trans>
            (d) Social Media Cookies. These cookies are used when you share
            information using a social media sharing button or “like” button on
            our Services or you link your account or engage with our content on
            or through a social networking website such as Facebook, Twitter or
            Google+. The social network will record that you have done this.
          </Trans>
        </Text>
        <Text>
          <Trans>
            (e) Targeted and Advertising Cookies. These cookies track your
            browsing habits to enable us to show advertising which is more
            likely to be of interest to you. These cookies use information about
            your browsing history to group you with other users who have similar
            interests. Based on that information, and with our permission,
            third-party advertisers can place cookies to enable them to show
            adverts which we think will be relevant to your interests while you
            are on third-party websites. These cookies also store your location,
            including your latitude, longitude, and GeoIP region ID, which helps
            us show you locale-specific news and allows our Services to operate
            more efficiently. You can disable cookies which remember your
            browsing habits and target advertising at you by visiting
          </Trans>{" "}
          <a
            href="http://www.youronlinechoices.com/uk/your-ad-choices"
            target="_blank"
          >
            http://www.youronlinechoices.com/uk/your-ad-choices
          </a>
          .
          <Trans>
            If you choose to remove targeted or advertising cookies, you will
            still see adverts but they may not be relevant to you. Even if you
            do choose to remove cookies by the companies listed at the above
            link, not all companies that serve online behavioral advertising are
            included in this list, and so you may still receive cookies and
            tailored adverts from companies that are not listed.
          </Trans>
        </Text>
        <Paragraph>
          <Text>
            <Trans>Flash</Trans>
          </Text>
        </Paragraph>
        <Text>
          <Trans>
            A Flash cookie is a data file placed on a device via the Adobe Flash
            plug-in that is built into or downloaded by you onto your device.
            Flash cookies are used for various purposes, including, without
            limitation, enabling a Flash feature and remembering your
            preferences. For more information about Flash and the privacy
            choices Adobe offers, visit
          </Trans>{" "}
          <a
            href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html"
            target="_blank"
          >
            http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html
          </a>
          .
          <Trans>
            If you choose to adjust your Flash privacy settings on your device
            some features of the Services may not function properly.
          </Trans>
        </Text>
        <Paragraph>
          <Text>
            <Trans>Pixel Tags</Trans>
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            <Trans>
              We also use 'pixel tags,' which are small graphic files that allow
              us and third parties to monitor the use of the Services and
              collect Usage Data. A pixel tag can collect information such as
              the IP address of the computer that downloaded the page on which
              the tag appears; the URL of the page on which the pixel tag
              appears; the time (and length of time) the page containing the
              pixel tag was viewed; the type of browser that retrieved the pixel
              tag; and the identification number of any cookie previously placed
              by that server on your computer.
            </Trans>
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            <Trans>
              We use pixel tags, either provided by us or by our third-party
              advertisers, service providers and ad networks, to collect
              information about your visit, including the pages you view, the
              links you click and other actions taken in connection with our
              sites and Services and use them in combination with our cookies to
              provide offers and information of interest to you. Pixel tags also
              enable ad networks to serve targeted advertisements to you when
              you visit the Services or other websites.
            </Trans>
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            <Trans>
              In addition, we use a variety of other technologies that collect
              similar information for security and fraud detection purposes.
            </Trans>
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            <Trans>
              For more information about the use of cookies and similar
              technology on our Site, please review Section 13 of this Privacy
              Policy. You can also find more information about cookies and how
              they work, what cookies have been set on your computer or mobile
              device and how to manage and delete them at
            </Trans>{" "}
            <a
              href="http://www.allaboutcookies.org and http://www.youronlinechoices.com/uk"
              target="_blank"
            >
              http://www.allaboutcookies.org and
              http://www.youronlinechoices.com/uk
            </a>
            .
          </Text>
        </Paragraph>

        <Title onPositionUpdate={onPositionUpdateHandler} item="3">
          3. {t("Information You Choose To Submit")}
        </Title>
        <Paragraph>
          <Text>
            <Trans>
              You can visit the Services without telling us who you are or
              revealing any information by which someone could identify you as a
              specific, identifiable individual (which we will refer to in this
              Privacy Policy collectively as 'Personal Data'). If, however, you
              wish to register to become a member of the Services, you are
              required to provide certain Personal Data (for example, your name
              and e-mail address), and you must also provide a user name and
              password. We use your Personal Data to fulfill your requests for
              products and services, to improve our Services, to contact you
              from time to time, with your consent, about us and our products
              and services, and as otherwise described in this Privacy Policy.
              In addition, you are required to provide certain Personal Data
              when you submit a job application and supporting materials. Where
              you submit a job application on behalf of another person, you
              confirm that you have made that person aware of how we collect,
              use and share their Personal Data, the reason you have provided
              it, how they can contact us, the terms of this Privacy Policy and
              related policies and that they have consented to such collection,
              use and sharing. You can also choose to submit or we may collect
              additional information about yourself, such as demographic
              information (for example your gender, birth date, or zip code) and
              information about your preferences and interests. Failure to
              provide any required Personal Data will prevent us from being able
              to provide the Services you request (e.g., member registration or
              submission of job application) or otherwise inhibit our ability to
              provide the Services.
            </Trans>
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            <Trans>
              We refer collectively to all information we collect that is not
              Personal Data, including Usage Data, demographic data and
              de-identified Personal Data, as 'Non-Personal Data'. If we combine
              Non-Personal Data with Personal Data, we will treat the combined
              information as Personal Data under this Privacy Policy.
            </Trans>
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            <Trans>
              You also can choose to submit your alias, bio, email, photos or
              any other information that you would like to share with other
              users of the Services (which we will refer to in this Privacy
              Policy collectively as 'User Submissions').
            </Trans>
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            <Trans>
              Personal Data, Non-Personal Data, and User Submissions are
              referred to in this Privacy Policy collectively as 'User
              Information.
            </Trans>
          </Text>
        </Paragraph>

        <Title onPositionUpdate={onPositionUpdateHandler} item="4">
          4. {t("Information We Receive From Other Sources")}
        </Title>
        <Paragraph>
          <Text>
            <Trans>
              We supplement the information we collect with outside records in
              order to learn more about our users, to better tailor the content
              and offers we show you, and for other purposes. We receive this
              information about you from public sources or third parties,
              including without limitation consumer data resellers and
              advertisers. We combine the information we receive from those
              other sources with information we collect through the Services. In
              those cases, we will apply this Privacy Policy to the combined
              information.
            </Trans>
          </Text>
        </Paragraph>

        <Title onPositionUpdate={onPositionUpdateHandler} item="5">
          5. {t("Information Use")}
        </Title>
        <Paragraph>
          <Text>
            <Trans>
              We use the information we collect, including Personal Data and
              Usage Data:
            </Trans>
          </Text>
        </Paragraph>
        <Text>
          <Trans>
            to enable you to use our Services, to create an account or profile,
            to process information you provide via our Services (including
            verifying that your email address is active and valid) and to
            process your transactions;
          </Trans>
        </Text>
        <Text>
          <Trans>
            to provide related customer service and care, including responding
            to your questions, complaints, or comments and sending surveys (with
            your consent) and processing survey responses;
          </Trans>
        </Text>
        <Text>
          <Trans>
            to provide you with information, products, or services that you have
            requested;
          </Trans>
        </Text>
        <Text>
          <Trans>
            to receive and process job applications for jobs with us;
          </Trans>
        </Text>
        <Text>
          <Trans>
            with your consent, to provide you with information, products, or
            services that we otherwise believe will interest you, including
            special opportunities from us and our third-party partners;
          </Trans>
        </Text>
        <Text>
          <Trans>
            to tailor content, recommendations, and advertisements we and third
            parties display to you, both on the Services and elsewhere online;
          </Trans>
        </Text>
        <Text>
          <Trans>
            for internal business purposes, such as to improve our Services;
          </Trans>
        </Text>
        <Text>
          <Trans>
            to administer and process contests, sweepstakes, and promotions;
          </Trans>
        </Text>
        <Text>
          <Trans>
            to contact you with administrative communications and, in our
            discretion, changes to our Privacy Policy, Terms of Use, or any of
            our other policies;
          </Trans>
        </Text>
        <Text>
          <Trans>
            to comply with regulatory and legal obligations; and for purposes as
            disclosed at the time you provide your information, with your
            consent, and as further described in this Privacy Policy.
          </Trans>
        </Text>

        <Title onPositionUpdate={onPositionUpdateHandler} item="6">
          6. {t("Social Network and Platform Integration")}
        </Title>
        <Paragraph>
          <Text>
            <Trans>
              The Services contain integration with social networks and other
              platforms in which information is shared between us and such
              platforms. For example, if you create or log into your account
              through a third-party social media site, we will have access to
              certain information from that site, such as your name, email
              address, account information, photo and friends lists, and other
              information in accordance with the authorization procedures
              determined by such social media site. If you visit our Services on
              a device through which you also interact with social networks or
              if you interact with us through a social media function such as a
              plug-in (for example, a Facebook \"like\" button) then you permit
              us to have on-going access to, to use and to store information
              from your social network profile (such as your name, email
              address, your friend list, photo, age, gender, location, birthday,
              social networking ID, current city, and the people/sites you
              follow) in accordance with this Privacy Policy. If you don't want
              a social network to collect the information about you as described
              above, or you don't want a social network to share it with us,
              please review the privacy policy, privacy settings and
              instructions of the applicable social network before you visit and
              use our Services.
            </Trans>
          </Text>
        </Paragraph>

        <Title onPositionUpdate={onPositionUpdateHandler} item="7">
          7. {t("Our Information Sharing Practice")}
        </Title>
        <Text>
          <Trans>
            (a) Generally. We share Non-Personal Data, including Usage Data,
            de-identified Personal Data and aggregated user statistics, with
            third parties in our discretion. We share User Information,
            including Personal Data, as otherwise described in this Policy, and
            under the following circumstances:
          </Trans>
        </Text>
        <Text>
          <Trans>
            (b) Service Providers. From time to time, we enter into
            relationships with third parties who provide services to us (for
            example, analytics companies, advertisers and ad agencies, data
            management and storage services or credit card processing services,
            sweepstakes or contest prize fulfillment). In those circumstances,
            we disclose User Information so that such service providers perform
            those services. These service providers are only permitted to use
            your Personal Data to the extent necessary to enable them to provide
            their services to us. They are required to follow our express
            instructions and to comply with appropriate security measures to
            protect your Personal Data. In particular, our sites use certain
            Google analytics and other services, and certain pages use the
            Google AMP Client ID API, each of which enable collection and
            sharing of your information (including Personal Data) with Google
            for further use. For specific information on Google usage and how to
            control it, please see How Google uses data when you use our
            partners' sites or apps and Google’s Privacy Policy.
          </Trans>
        </Text>
        <Text>
          <Trans>
            (c) Third-Party Marketing. You will have a choice (opt-in or
            opt-out) before we share your information with third parties for
            marketing purposes. Provided that you have chosen to receive
            marketing messages from third parties, we share your information
            (including Personal Data) with third parties for those third
            parties' own direct marketing purposes. Please note, messages
            delivered from a third-party will subject you to the third-party's
            privacy policy. We also match your email address with third parties
            with whom you have also consented to share your email address and
            use such match to deliver custom offers or emails to you on the
            Services and off the Services provided you have consented to
            receiving such offers or emails.
          </Trans>
        </Text>
        <Text>
          <Trans>
            (d) Change of Control. In the event we go through a business
            transition (such as a merger, acquisition by another company,
            bankruptcy, or sale of all or a portion of our assets, including,
            without limitation, during the course of any due diligence process),
            your Personal Data will likely be among the assets transferred. By
            providing your Personal Data, you agree that we can transfer such
            information in those circumstances without your further consent.
            Should such a business transition occur, we will make reasonable
            efforts to request that the new owner or combined entity (as
            applicable) follow this Privacy Policy with respect to your Personal
            Data. If your Personal Data would be used contrary to this Privacy
            Policy, we will request that you receive prior notice.
          </Trans>
        </Text>

        <Title onPositionUpdate={onPositionUpdateHandler} item="8">
          8. {t("Anonymous Data")}
        </Title>
        <Text>
          <Trans>
            When we use the term "anonymous data," we are referring to data and
            information that does not permit you to be identified or
            identifiable, either alone or when combined with any other
            information available to a third-party. We may create anonymous data
            from the Personal Data we receive about you and other individuals
            whose Personal Data we collect. Anonymous data will include
            analytics information and information collected by us using cookies.
            We make Personal Data into anonymous data by excluding information
            (such as your name) that makes the data personally identifiable to
            you. We use this anonymous data to analyze usage patterns in order
            to make improvements to our Services.
          </Trans>
        </Text>

        <Title onPositionUpdate={onPositionUpdateHandler} item="9">
          9. {t("Public Information")}
        </Title>
        <Text>
          <Trans>
            If you identify any User Information as public, you are authorizing
            us to share such information publicly. For example, you can elect to
            make certain of your User Submissions (such as your alias, bio,
            email, or photos) publicly available. Also, there are areas of the
            Services (for example, message boards, discussion rooms, and other
            online forums) in which you are able to post information that
            automatically will be available to all other users of the Services.
            By choosing to use these areas, you understand and agree that anyone
            can access, use, and disclose any information that you post to those
            areas.
          </Trans>
        </Text>

        <Title onPositionUpdate={onPositionUpdateHandler} item="10">
          10. {t("Users Outside of the United States and Consent to Transfer")}
        </Title>
        <Text>
          <Trans>
            The Services are operated in the United States. If you are located
            in another jurisdiction, please be aware that information you
            provide to us will be transferred to, stored and processed in the
            United States. By using the Services or providing us with any
            information, you consent to this transfer, processing, and storage
            of your information in the United States, a jurisdiction in which
            the privacy laws are not as comprehensive as those in the country
            where you reside or are a citizen such as the European Union. You
            understand that the U.S. government can obtain access to the
            Personal Data you submit if necessary for investigative purposes
            (e.g., terrorism investigation). We will take all steps reasonably
            necessary to ensure that your data is treated securely and in
            accordance with this Privacy Policy. We use appropriate and suitable
            safeguards for transferring your Personal Data to the U.S. (e.g.,
            standard contractual clauses issued by the European Commission,
            which can be accessed here:
          </Trans>{" "}
          <a
            href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en"
            target="_blank"
          >
            https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en
          </a>
        </Text>

        <Title onPositionUpdate={onPositionUpdateHandler} item="11">
          11.{" "}
          {t(
            "Important Information for California Residents-Your California Privacy Rights"
          )}
        </Title>
        <Paragraph>
          <Text>
            <Trans>
              As stated elsewhere in this Privacy Policy, Slash Audio does not
              share Personal Data with third parties for their direct marketing
              purposes, as defined by California Civil Code Section 1798.83,
              unless we give you choice (opt-in or opt-out) before sharing with
              those third parties.
            </Trans>
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            <Trans>
              If you are a California resident and you have questions about our
              practices with respect to sharing information with third parties
              for their direct marketing purposes and your ability to exercise
              choice, please contact us at Slash Audio, Inc., c/o Data
              Protection Officer, 12400 Ventura Blvd #122, Studio City, CA
              91604. You must put the statement "Your California Privacy Rights"
              in the body of your request, as well as your name, street address,
              city, state, and zip code. In the body of your request, please
              provide enough information for us to determine if this applies to
              you. Please note that we will not accept inquiries via the
              telephone, email, or by facsimile, and we are not responsible for
              notices that are not labeled or sent properly, or that do not have
              complete information.
            </Trans>
          </Text>
        </Paragraph>

        <Title onPositionUpdate={onPositionUpdateHandler} item="12">
          12. {t('How We Respond to "Do Not Track" Signals')}
        </Title>
        <Paragraph>
          <Text>
            <Trans>
              Internet browsers can be configured to send "Do Not Track" signals
              to the online services that you visit. California Business &
              Professions Code Section 22575(b) (as amended effective January 1,
              2014) provides that California residents are entitled to know how
              Slash Audio responds to "Do Not Track" browser settings.
            </Trans>
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            <Trans>
              There currently is no consensus among industry participants as to
              what "Do Not Track" means in this context. Therefore, like many
              websites and online services, the Services do not alter their
              practices when they receive a "Do Not Track" signal from a
              visitor's browser. To find out more about "Do Not Track," please
              visit
            </Trans>{" "}
            <a href="http://www.allaboutdnt.com" target="_blank">
              http://www.allaboutdnt.com
            </a>
          </Text>
        </Paragraph>

        <Title onPositionUpdate={onPositionUpdateHandler} item="13">
          13. {t("Advertising")}
        </Title>
        <Paragraph>
          <Text>
            <Trans>
              (a) Generally. We use other companies under agreements with us to
              serve third-party advertisements when you visit and use the
              Services. These companies collect and use click stream
              information, browser type, time and date, subject of
              advertisements clicked or scrolled over during your visits to the
              Services and other Web sites in order to provide advertisements
              about goods and services likely to be of greater interest to you.
              These companies typically use tracking technologies to collect
              this information. Other companies' use of their tracking
              technologies is subject to their own privacy policies, not this
              one.
            </Trans>
          </Text>
          <Text>
            <Trans>
              (b) Targeted Advertising. In order to serve offers and
              advertisements that may be of interest to our users, we display
              targeted advertisements on the Services, or other digital
              properties or applications in conjunction with our content, based
              on information provided to us by our users and information
              provided to us by third parties that they have independently
              collected. We do not provide Personal Data to our advertisers when
              you interact with an advertisement.
            </Trans>
          </Text>
          <Text>
            <Trans>
              (c) Your Ad Choices. Some of the third-party services providers
              and/or Advertisers may be members of the Network Advertising
              Initiative ("NAI") or the Digital Advertising Alliance ("DAA")
              Self-Regulatory Program for Online Behavioral Advertising. You can
              visit
            </Trans>{" "}
            <a
              href="http://www.networkadvertising.org/managing/opt_out.asp"
              target="_blank"
            >
              http://www.networkadvertising.org/managing/opt_out.asp
            </a>
            ,
            <Trans>
              which provides information regarding targeted advertising and the
              "opt-out" procedures of NAI members. You can opt-out of our use of
              your Web site viewing behavior data to serve you interest-based
              advertising on third-party sites and email newsletters here
            </Trans>{" "}
            <a href="http://www.aboutads.info/choices" target="_blank">
              http://www.aboutads.info/choices
            </a>
            .
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            <Trans>
              If you are accessing the Services through an application (i.e.,
              mobile phone or tablet) you can download the AppChoices
              application from your device's application store (i.e., Google
              Play, Apple App Store, and Amazon Store). This DAA application
              allows participating companies to offer an opt-out of customized
              advertisements that are based on predictions about your interests
              generated from your application usage. For more information, visit
            </Trans>{" "}
            <a href="http://www.aboutads.info/appchoices" target="_blank">
              http://www.aboutads.info/appchoices
            </a>
            .
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            <Trans>
              Please note opting out through these mechanisms does not opt you
              out of being served advertising. You will continue to receive
              generic ads while online or on your device.
            </Trans>
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            <Trans>
              (d) Mobile. We may from time to time offer certain location or
              pinpoint based services, such as location assisted navigation
              instruction. If you elect to use such location-based services, we
              must periodically receive your location in order to provide such
              location-based services to you. By using the location-based
              services, you authorize us to: (i) locate your hardware; (ii)
              record, compile and display your location; and (iii) publish your
              location to third parties designated by you by means of location
              publication controls available within the applications (for
              example, settings, user preferences). As part of the
              location-based services, we also collect and store certain
              information about the users who elect to use such location-based
              services, such as a device id. This information will be used to
              provide you the location-based services. We use third-party
              providers to help provide location-based services through mobile
              systems (unless you opt out of such location-based services with
              such providers) and we provide the information to such providers
              to enable them to provide their location-based services, provided
              that such providers use the information in accordance with our
              Privacy Policy.
            </Trans>
          </Text>
        </Paragraph>

        <Title onPositionUpdate={onPositionUpdateHandler} item="14">
          14. {t("Choice Opt-Out From Communications")}
        </Title>
        <Text>
          <Trans>
            We offer you the opportunity to manage your communications from us.
            Even after subscribing to one or more newsletters and/or opting in
            to one or more offers to receive marketing and/or promotional
            communications from us or our third-party partners, users may elect
            to modify their preferences by following the "Communications
            Preferences" and/or "Unsubscribe" link provided in an email or
            communication received. You may also be able to change your
            preferences by updating your profile or account, depending on which
            of our Services you are using. Please be aware that if you wish to
            remove yourself from a newsletter and/or other marketing emails from
            third parties that you consented to through the Services, you must
            do so by contacting the relevant third-party. Even if you do opt-out
            of marketing emails, we reserve the right to send you transactional
            and administrative emails including those related to the Services,
            service announcements, notices of changes to this Privacy Policy or
            other Services policies, and to contact you regarding any goods or
            services you have ordered.
          </Trans>
        </Text>

        <Title onPositionUpdate={onPositionUpdateHandler} item="15">
          15. {t("Retaining, Modifying and Deleting Your Personal Data")}
        </Title>
        <Paragraph>
          <Text>
            <Trans>
              You may access the information we hold about you. If you wish to
              exercise this right, please contact us using the details in the
              Contact Us section below. If you would like to update, correct,
              modify or delete from our database any Personal Data you
              previously submitted to us, please let us know by accessing and
              updating your profile. If you delete certain information you may
              not be able to order services in the future without re-submitting
              such information. We will comply with your request as soon as
              reasonably practicable. Also, please note that we will maintain
              Personal Data in our database whenever we are required to do so by
              law.
            </Trans>
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            Please note that we need to retain certain information for record
            keeping purposes and/or to complete any transactions that you began
            prior to requesting such change or deletion (for example, when you
            enter a promotion, you may not be able to change or delete the
            Personal Data provided until after the completion of such
            promotion). We will retain your Personal Data for the period
            necessary to fulfill the purposes outlined in this Policy unless a
            longer retention period is required or permitted by law.
          </Text>
        </Paragraph>

        <Title onPositionUpdate={onPositionUpdateHandler} item="16">
          16. {t("EU Data Subject Rights")}
        </Title>
        <Text>
          <Trans>
            If you are a resident of the European Economic Area (EEA), you have
            the right to: (a) request access to your Personal Data and
            rectification of inaccurate Personal Data; (b) request erasure of
            your Personal Data; (c) request restrictions on the processing of
            your Personal Data; (d) object to processing your Personal Data;
            and/or (e) the right to data portability ("collectively,
            "Requests"). We can only process Requests from a user whose identity
            has been verified. To verify your identity, please provide your
            email address or [URL] when you make a request. For more information
            about how to get access to Personal Data and for exercising your
            rights, you can submit a request via email to support@Slash
            Audio.com. You also have the right to lodge a complaint with a
            supervisory authority.
          </Trans>
        </Text>

        <Title onPositionUpdate={onPositionUpdateHandler} item="17">
          17. {t("Security")}
        </Title>
        <Text>
          <Trans>
            We have implemented commercially reasonable and appropriate
            technical and organizational security measures to help protect your
            Personal Data from accidental or unlawful destruction, loss,
            alteration, misuse, or unauthorized access or disclosure;
            unfortunately, however, no data transmission over the Internet can
            be guaranteed to be 100% secure. As a result, while we strive to
            protect your User Information, we cannot guarantee its security. You
            use the Services and provide us with information at your own
            initiative and risk. If you have reason to believe that your
            interaction with us is no longer secure (for example, if you feel
            that the security of any account you have with us has been
            compromised), please immediately notify us of the problem by
            contacting us using the details in the Contact Us section below.
          </Trans>
        </Text>

        <Title onPositionUpdate={onPositionUpdateHandler} item="18">
          18. {t("Links")}
        </Title>
        <Text>
          <Trans>
            The Services contain links to other websites that we do not control,
            and the Services contain videos, advertising and other content
            hosted and served by third parties. We are not responsible for the
            privacy practices of any third-party.
          </Trans>
        </Text>

        <Title onPositionUpdate={onPositionUpdateHandler} item="19">
          19. {t("Children's Privacy")}
        </Title>
        <Text>
          <Trans>
            The Services are intended for a general audience and are not
            intended for and should not be used by children under the age of 13.
            We do not knowingly collect information from children under the age
            of 13 and we do not target the Services to children under the age of
            13. If a parent or guardian becomes aware that his or her child has
            provided us with information without their consent, he or she should
            contact us using the details in the Contact Us section below. We
            will delete such information from our files as soon as reasonably
            practicable.
          </Trans>
        </Text>

        <Title onPositionUpdate={onPositionUpdateHandler} item="20">
          20. {t("Sensitive Personal Data")}
        </Title>
        <Paragraph>
          <Text>
            <Trans>
              Subject to the following paragraph, we ask that you not send us,
              and you not disclose, any sensitive personal data as this term is
              defined under applicable data protection and privacy laws (for
              example, social security numbers, information related to racial or
              ethnic origin, political opinions, religion or other beliefs,
              health, biometrics or genetic characteristics, criminal background
              or trade union membership) on or through the Services or otherwise
              to us.
            </Trans>
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            <Trans>
              If you send or disclose any sensitive personal data to us, you
              consent to our processing and use of such sensitive personal data
              in accordance with this Privacy Policy. If you do not consent to
              our processing and use of such sensitive personal data, you must
              not submit such content to our Services.
            </Trans>
          </Text>
        </Paragraph>

        <Title onPositionUpdate={onPositionUpdateHandler} item="21">
          21. {t("Changes")}
        </Title>
        <Text>
          <Trans>
            We update this Privacy Policy from time to time in our discretion
            and will notify you of any material changes to the way in which we
            treat Personal Data, by posting a notice on relevant areas of the
            Services. We will also provide notice to you in other ways in our
            discretion, such as through contact information you have provided.
            Any updated version of this Privacy Policy will be effective
            immediately upon the posting of the revised Privacy Policy unless
            otherwise specified. Your continued use of the Services after the
            effective date of the revised Privacy Policy (or such other act
            specified at that time) will constitute your consent to those
            changes. However, we will not, without your consent, use your
            Personal Data in a manner materially different than what was stated
            at the time your Personal Data was collected.
          </Trans>
        </Text>

        <Title onPositionUpdate={onPositionUpdateHandler} item="22">
          22. {t("Contact Us")}
        </Title>
        <Text>
          <Trans>
            If you have any questions about this Privacy Policy, please feel
            free to contact us by as follows:
          </Trans>
        </Text>

        <Paragraph>
          <Text>
            <Trans>Slash Audio</Trans>
          </Text>
        </Paragraph>

        <Paragraph>
          <Text>
            <Trans>Attn: Data Protection Officer</Trans>
          </Text>
        </Paragraph>

        <Paragraph>
          <Text>
            <Trans>12400 Ventura Blvd #122</Trans>
          </Text>
        </Paragraph>

        <Paragraph>
          <Text>
            <Trans>Studio City, CA 91604</Trans>
          </Text>
        </Paragraph>

        <Paragraph>
          <Text>support(at)slashaudio.com</Text>
        </Paragraph>
      </PrivacyContainer>
    </>
  );
};

export default memo(PrivacyPage);
