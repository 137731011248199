import styled from "styled-components";

export const ImageStyled = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 290px;
  height: 290px;
  align-items: center;
  justify-content: center;
  border: 2px solid #cccccc;
  border-radius: 100%;
  padding: 30px 50px 50px 50px;
  box-sizing: border-box;

  svg {
    width: 100%;
  }
`;
