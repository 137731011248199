import React, { memo, useState, useEffect, useRef } from "react";
import { PlayButtonStyled } from "./style";

const PlayButton = ({ playing, ...props }) => {
  const [shape1From, setShape1From] = useState();
  const [shape1To, setShape1To] = useState("0,0 150,75 150,225 0,300");
  const [shape2From, setShape2From] = useState();
  const [shape2To, setShape2To] = useState("150,75 300,150 300,150 150,225");
  const [lock, setLock] = useState(true);
  const shape1 = useRef();
  const shape2 = useRef();

  useEffect(() => {
    playing && play();
  }, []);

  useEffect(() => {
    if (lock) {
      setLock(false);
      return;
    }
    playing ? play() : pause();
  }, [playing]);

  const play = () => {
    setShape1From("0,0 150,75 150,225 0,300");
    setShape1To("0,0 110,0 110,300 0,300");
    setShape2From("150,75 300,150 300,150 150,225");
    setShape2To("190,0 300,0 300,300 190,300");
    shape1.current.beginElement();
    shape2.current.beginElement();
  };

  const pause = () => {
    setShape1From("0,0 110,0 110,300 0,300");
    setShape1To("0,0 150,75 150,225 0,300");
    setShape2From("190,0 300,0 300,300 190,300");
    setShape2To("150,75 300,150 300,150 150,225");
    shape1.current.beginElement();
    shape2.current.beginElement();
  };

  return (
    <PlayButtonStyled {...props}>
      <svg viewBox="0 0 300 300">
        <polygon points="0,0 150,75 150,225 0,300">
          <animate
            ref={shape1}
            begin="indefinite"
            attributeName="points"
            dur="200ms"
            to={shape1To}
            from={shape1From}
            fill="freeze"
          />
        </polygon>
        <polygon points="150,75 300,150 300,150 150,225">
          <animate
            ref={shape2}
            begin="indefinite"
            attributeName="points"
            dur="200ms"
            to={shape2To}
            from={shape2From}
            fill="freeze"
          />
        </polygon>
      </svg>
    </PlayButtonStyled>
  );
};

export default memo(PlayButton);
