import React, { memo } from "react";
import { ImageStyled } from "./style";
import Icon from "components/Icon";

import DonateIconSrc from "icons/donation.svg";

const Image = () => {
  return (
    <ImageStyled>
      <Icon src={DonateIconSrc} alt="donation" />
    </ImageStyled>
  );
};

export default memo(Image);
