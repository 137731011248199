import styled, { css } from "styled-components";

export const ProgressStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  margin-right: 15px;
  margin-left: 15px;
  cursor: pointer;
`;

export const Line = styled.div`
  position: relative;
  background: #e9e9e9;
  width: 100%;
  height: 5px;
  pointer-events: none;
`;

export const ProgressLine = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  transition: width 0.1s;

  ${(props) =>
    props.width &&
    css`
      width: ${props.width}%;
    `}
`;

export const GradientLine = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(253, 226, 100, 1) -5%,
    rgba(208, 18, 46, 1) 26%,
    rgba(33, 63, 131, 1) 85%,
    rgba(253, 226, 100, 1) 130%
  );
  pointer-events: none;

  ${(props) =>
    props.width &&
    css`
      width: ${props.width}px;
    `}
`;
