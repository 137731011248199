import styled from "styled-components";
import { device } from "responsive";
import Icon from "components/Icon";

export const VolumeBarStyled = styled.div`
  display: flex;
  align-items: center;
  margin-left: 40px;

  @media ${device.sm} {
    display: none;
  }

  .MuiSlider-root {
    height: 5px;
    width: 85px;
    margin-left: 15px;
    margin-right: 15px;

    @media ${device.md} {
      width: 100px;
    }
  }

  .MuiSlider-rail {
    height: 5px;
    color: #e9e9e9;
    opacity: 1;
  }

  .MuiSlider-track {
    height: 5px;
    color: #191919;
  }

  .MuiSlider-thumb {
    display: none;
  }
`;

export const VolumeIcon = styled(Icon)`
  cursor: pointer;
`;
