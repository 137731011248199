import styled from "styled-components";
import Button from "components/Button";

export const DonateBlockStyled = styled.div`
  margin: 50px 0;
`;

export const Title = styled.div`
  font-size: 20px;
  text-align: center;
`;

export const Link = styled.a`
  color: #d5002c;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const Text = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: 100;
  margin: 20px 0;
`;

export const DonateButton = styled(Button)`
  height: 45px;
  margin-top: 50px;
`;
