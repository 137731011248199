export const OPEN_PLAYER = "OPEN_PLAYER";
export const CLOSE_PLAYER = "CLOSE_PLAYER";
export const START_PLAYER = "START_PLAYER";
export const STOP_PLAYER = "STOP_PLAYER";
export const CHANGE_CONTEXT = "CHANGE_CONTEXT";
export const PUSH_SONGS = "PUSH_SONGS";
export const UPDATE_SONGS = "UPDATE_SONGS";
export const SEEK_TO = "SEEK_TO";
export const CHANGE_HISTORY = "CHANGE_HISTORY";

export function openPlayer() {
  return {
    type: OPEN_PLAYER,
  };
}

export function closePlayer() {
  return {
    type: CLOSE_PLAYER,
  };
}

export function play(id) {
  return (dispatch, getState) => {
    const state = getState();
    if (state.player.currentId !== id) {
      axios.post(`/tracks/${id}/stream`);
    }
    dispatch({
      type: START_PLAYER,
      id: id,
    });
  };
}

export function pause() {
  return {
    type: STOP_PLAYER,
  };
}

export function changeContext(id, list) {
  return (dispatch, getState) => {
    const state = getState();
    if (state.player.contextId !== id) {
      dispatch({
        type: CHANGE_CONTEXT,
        id: id,
        list: list,
      });
    }
  };
}

export function seekTo(progress) {
  return {
    type: SEEK_TO,
    progress: progress,
  };
}

export function pushSongs(list) {
  return {
    type: PUSH_SONGS,
    list: list,
  };
}

export function updateSongs(list) {
  return {
    type: UPDATE_SONGS,
    list: list,
  };
}

export function playNext() {
  return (dispatch, getState) => {
    const state = getState();
    const currentId = state.player.currentId;
    let nextId = false;
    state.player.items.find((item, index) => {
      if (item.id == currentId && state.player.items[index + 1]) {
        nextId = +state.player.items[index + 1].id;
      }
    });
    if (nextId) {
      dispatch(play(nextId));
    }
  };
}

export function playPrev() {
  return (dispatch, getState) => {
    const state = getState();
    const currentId = state.player.currentId;
    let prevId = false;
    state.player.items.find((item, index) => {
      if (item.id == currentId && state.player.items[index - 1]) {
        prevId = +state.player.items[index - 1].id;
      }
    });
    if (prevId) {
      dispatch(play(prevId));
    }
  };
}

export function changeProgressHistory(id, progress) {
  return {
    type: CHANGE_HISTORY,
    id: id,
    progress: progress,
  };
}
