import styled, { css } from "styled-components";
import { device } from "responsive";

export const ButtonStyled = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  padding: 0;
  border-radius: 80px;
  text-transform: uppercase;
  height: 35px;
  font-family: "Exo", sans-serif;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  outline: none;
  transition: transform 0.1s linear;
  width: 100%;

  &:active {
    transform: scale(0.96);
  }

  @media ${device.xs} {
    cursor: inherit;
  }

  ${(props) =>
    props.width &&
    css`
      max-width: ${props.width}px;
    `}

  ${(props) =>
    props.black &&
    css`
      border: 2px solid black;
      background: white;
    `}

    ${(props) =>
      props.white &&
      css`
        border: 2px solid white;
        color: white;
        background: transparent;
      `}

    ${(props) =>
      props.gradient &&
      css`
        background: linear-gradient(
          90deg,
          rgb(242, 124, 69) 0%,
          rgb(194, 19, 49) 33.58%,
          rgb(35, 53, 127) 68.25%,
          rgb(129, 145, 122) 100%
        );
        color: white;
      `}

    ${(props) =>
      props.left &&
      css`
        margin-left: 0;
      `}

    ${(props) =>
      props.right &&
      css`
        margin-right: 0;
      `}
`;
