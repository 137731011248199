import React, { memo, useState, useCallback } from "react";
import { ItemStyled, Left, Center, Right, Title, Description } from "./style";
import ArrowIcon from "./components/ArrowIcon";
import OpenIcon from "./components/OpenIcon";
import IsDesktop from "components/IsDesktop";
import { size } from "responsive";

const Item = ({ title, children }) => {
  const [open, setOpen] = useState(false);

  const onClickOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <ItemStyled>
      <IsDesktop width={size.xs}>
        <Left>
          <ArrowIcon />
        </Left>
      </IsDesktop>
      <Center>
        <Title>{title}</Title>
        <Description open={open}>{children}</Description>
      </Center>
      <Right>
        <OpenIcon open={open} onClick={onClickOpen} />
      </Right>
    </ItemStyled>
  );
};

export default memo(Item);
