import React, { memo, useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { ShareModalStyled, ShareButton } from "./style";
import { Title } from "./style";
import Modal from "../../../Modal";
import CloseButton from "../../components/CloseButton";
import { useValidator } from "react-validate-fields";
import { validateRules, validateMessages } from "validator";
import Input from "components/Form/Input";

import MailIcon from "icons/mail.svg";
import UserIcon from "icons/user.svg";

const ShareModal = ({ open, onClose, trackId }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const { validate, errors, setErrors } = useValidator(
    validateRules,
    validateMessages
  );

  const onChangeEmail = useCallback(
    (e) => {
      setEmail(e.target.value);
    },
    [email]
  );

  const onChangeName = useCallback(
    (e) => {
      setName(e.target.value);
    },
    [name]
  );

  const onSubmitHandler = useCallback(() => {
    validate({ email })
      .then(() => {
        setLoading(true);
        axios
          .post(window.location.origin + `/tracks/${trackId}/share_via_email`, {
            email,
            name,
          })
          .then(() => {
            setLoading(false);
            onClose();
          })
          .catch(() => setLoading(false));
      })
      .catch(() => {});
  }, [email, trackId]);

  useEffect(() => {
    if (!open) {
      setEmail("");
      setName("");
      setErrors({
        email: [],
      });
    }
  }, [open]);

  return (
    <Modal open={open} onClose={onClose} width={570}>
      <ShareModalStyled>
        <CloseButton onClick={onClose} />
        <Title>Share</Title>
        <Input
          icon={UserIcon}
          placeholder="Your name"
          value={name}
          onChange={onChangeName}
          onPressEnter={onSubmitHandler}
        />
        <Input
          icon={MailIcon}
          placeholder="Email address"
          errors={errors["email"]}
          value={email}
          onChange={onChangeEmail}
          className="email-field"
          onPressEnter={onSubmitHandler}
        />
        <ShareButton
          onClick={onSubmitHandler}
          loading={loading}
          gradient
          className="button"
        >
          Submit
        </ShareButton>
      </ShareModalStyled>
    </Modal>
  );
};

ShareModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  trackId: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    global: state.global.info,
  };
}

export default connect(mapStateToProps)(memo(ShareModal));
