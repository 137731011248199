import React, { memo } from "react";
import { Wrapper, Field, FieldIcon, Error } from "../../style";

const Input = ({ icon, width, errors, cardElement, alt }) => {
  return (
    <Wrapper>
      <Field width={width}>
        {icon && <FieldIcon src={icon} alt={alt} />}
        {cardElement}
      </Field>
      {errors &&
        !!errors.length &&
        errors.map((error, i) => <Error key={i}>{error}</Error>)}
    </Wrapper>
  );
};

export default memo(Input);
