import styled, { css } from "styled-components";

export const SlideStyled = styled.div`
  position: absolute;
  right: 40px;
  top: 0;
  z-index: 9;
  visibility: hidden;
  overflow: hidden;
  padding-bottom: 5px;
  padding-left: 15px;

  ${(props) =>
    !props.open &&
    css`
      transition: visibility 0s ease-in 0.3s;
    `}

  ${(props) =>
    props.open &&
    css`
      visibility: visible;

      div${Panel} {
        transform: translateX(0);
      }
    `}
`;

export const Panel = styled.div`
  display: flex;
  align-items: center;
  z-index: 9;
  background: #f4f4f4;
  height: 40px;
  box-sizing: border-box;
  transform: translateX(calc(100% + 1px));
  transition: transform 0.2s ease-in;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
`;
