import React, { memo } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { MenuStyled, Panel, Header, CloseIcon } from "./style";
import Item from "./components/Item";
import { useTranslation } from "react-i18next";

import CloseIconSrc from "icons/close.svg";

const Menu = ({ open, onClose }) => {
  const { t } = useTranslation();

  return (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={onClose}>
      <MenuStyled open={open}>
        <Panel>
          <Header>
            <CloseIcon src={CloseIconSrc} alt="close menu" onClick={onClose} />
          </Header>
          <Item title={t("Music")} to="/" onClick={onClose} />
          <Item title={t("About Us")} to="/about-us" onClick={onClose} />
          <Item title={t("FAQ")} to="/faq" onClick={onClose} />
          <Item title={t("Contacts")} to="/contacts" onClick={onClose} />
          <Item title={t("Donation")} to="/donation" onClick={onClose} />
          <Item title={t("Terms")} to="/term-of-use" onClick={onClose} />
          <Item
            title={t("Privacy policy")}
            to="/privacy-policy"
            onClick={onClose}
          />
        </Panel>
      </MenuStyled>
    </ClickAwayListener>
  );
};

export default memo(Menu);
