import React, { memo, useRef, useEffect } from "react";
import { SlideStyled } from "./style";
import { useWindowSize } from "hooks";

const Slide = ({ children, index, onChangeWidth }) => {
  const ref = useRef();

  const updateWidth = () => {
    if (ref.current) {
      onChangeWidth(index, {
        width: ref.current.clientWidth,
        offset: ref.current.offsetLeft,
      });
    }
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  return <SlideStyled ref={ref}>{children}</SlideStyled>;
};

export default memo(Slide);
