import {
  OPEN_PLAYER,
  CLOSE_PLAYER,
  STOP_PLAYER,
  START_PLAYER,
  CHANGE_CONTEXT,
  PUSH_SONGS,
  UPDATE_SONGS,
  SEEK_TO,
  CHANGE_HISTORY,
} from "../actions/player";

const defaultState = {
  contextId: null,
  items: [],
  open: false,
  playing: false,
  currentId: null,
  seekTo: 0,
  history: [],
};

function player(state = defaultState, action) {
  switch (action.type) {
    case OPEN_PLAYER:
      return Object.assign({}, state, {
        open: true,
      });
    case CLOSE_PLAYER:
      return Object.assign({}, state, {
        open: false,
        playing: false,
      });
    case START_PLAYER:
      return Object.assign({}, state, {
        open: true,
        currentId: action.id,
        playing: true,
      });
    case STOP_PLAYER:
      return Object.assign({}, state, {
        playing: false,
      });
    case CHANGE_CONTEXT:
      return Object.assign({}, state, {
        contextId: action.id,
        items: action.list,
      });
    case PUSH_SONGS:
      return Object.assign({}, state, {
        list: [...state.list, action.list],
      });
    case UPDATE_SONGS:
      return Object.assign({}, state, {
        list: action.list,
      });
    case SEEK_TO:
      return Object.assign({}, state, {
        seekTo: action.progress,
      });
    case CHANGE_HISTORY:
      const history = Object.assign({}, state.history, {
        [action.id]: action.progress,
      });
      return Object.assign({}, state, {
        history: history,
      });

    default:
      return state;
  }
}

export default player;
