import React, { memo } from "react";
import { PaginationStyled } from "./style";
import { usePagination } from "@material-ui/lab/Pagination";
import Item from "./components/Item";
import { useMobile } from "hooks";

const Pagination = ({ count, onChange, page, ...props }) => {
  const mobile = useMobile();

  const { items } = usePagination({
    count,
    page,
    hideNextButton: mobile,
    hidePrevButton: mobile,
    onChange: (event, value) => {
      onChange(value);
    },
  });

  if (count < 2) {
    return null;
  }

  return (
    <PaginationStyled {...props}>
      {items.map(({ type, onClick, page, selected, disabled }, index) => (
        <Item
          key={index}
          type={type}
          onClick={onClick}
          page={page}
          selected={selected}
          disabled={disabled}
        />
      ))}
    </PaginationStyled>
  );
};

export default memo(Pagination);
