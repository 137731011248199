const defaultState = {
  info: {
    cloud_front_url: "",
    stripe_key: "",
    gtag_key: "",
  },
};

function global(state = defaultState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default global;
