import React, { memo } from "react";
import { BlockStyled, Title, Content } from "./style";

const Block = ({ title, children }) => {
  return (
    <BlockStyled>
      <Title>{title}</Title>
      <Content>{children}</Content>
    </BlockStyled>
  );
};

export default memo(Block);
