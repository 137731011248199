import styled from "styled-components";
import Image from "components/Image";
import { device } from "responsive";

export const NotFoundPageStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;

  .button {
    height: 45px;
    margin-top: 40px;
  }
`;

export const Block = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 650px;
  width: 100%;
  margin: 50px;

  @media ${device.sm} {
    justify-content: center;
  }
`;

export const Left = styled.div`
  @media ${device.sm} {
    display: none;
  }
`;

export const Right = styled.div`
  max-width: 275px;
`;

export const Image404 = styled(Image)``;

export const Title = styled.div`
  font-size: 150px;
  font-weight: bold;
`;

export const Text = styled.div`
  font-size: 20px;
  font-weight: bold;
`;
