import styled from "styled-components";
import { device } from "responsive";

export const PlayButtonStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100%;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border: 2px solid #333;

  @media ${device.xxs} {
    width: 40px;
    height: 40px;
  }

  svg {
    fill: #333;
    width: 16px;
    height: 16px;
  }
`;
