import React, { memo, useCallback } from "react";
import { SearchStyled, SearchIcon, SearchInput } from "./style";
import { useTranslation } from "react-i18next";

import SearchIconSrc from "icons/search.svg";

const Search = ({ value, onChange, onSearch }) => {
  const { t } = useTranslation();

  const onChangeHandler = useCallback((e) => {
    onChange(e.target.value);
  }, []);

  const onPressKey = useCallback(
    (e) => {
      if (e.keyCode === 13 && onSearch) {
        onSearch();
      }
    },
    [value]
  );

  const onClickSearch = useCallback(() => {
    if (onSearch) {
      onSearch();
    }
  }, [value]);

  return (
    <SearchStyled>
      <SearchInput
        value={value}
        onChange={onChangeHandler}
        onKeyDown={onPressKey}
        placeholder={t("search") + "..."}
      />
      <SearchIcon
        src={SearchIconSrc}
        alt={t("search")}
        onClick={onClickSearch}
      />
    </SearchStyled>
  );
};

export default memo(Search);
