import styled from "styled-components";
import Button from "components/Button";
import Image from "components/Image";
import { device } from "responsive";

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
`;

export const TextBlock = styled.div`
  max-width: 500px;
  padding: 15px;

  @media ${device.md} {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  @media ${device.xs} {
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: inherit;
  }
`;

export const Title = styled.div`
  font-size: 40px;
  font-weight: bold;
  line-height: 53px;
  margin-bottom: 25px;

  @media ${device.xs} {
    font-size: 30px;
  }

  @media ${device.xxs} {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const Text = styled.div`
  font-size: 15px;
  line-height: 30px;

  @media ${device.xs} {
    margin-top: 25px;
  }
`;

export const Number = styled(Image)`
  height: 600px;
  width: 885px;

  @media ${device.md} {
    max-width: 400px;
    width: 100%;
    height: inherit;
  }

  @media ${device.xs} {
    display: none;
  }
`;

export const IconImage = styled(Image)`
  height: 600px;
  width: 600px;

  @media ${device.md} {
    max-width: 400px;
    width: 100%;
    height: inherit;
  }

  @media ${device.xs} {
    display: none;
  }
`;

export const FooterBlock = styled.div`
  max-width: 300px;
  width: 100%;

  @media ${device.xs} {
    max-width: inherit;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 200px;
  margin-bottom: 140px;

  @media ${device.sm} {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  @media ${device.xs} {
    flex-direction: column;
  }
`;

export const DonateButton = styled(Button)`
  width: 150px;
  height: 45px;
  font-size: 16px;

  @media ${device.sm} {
    margin: 0;
    margin-left: 15px;
  }

  @media ${device.xs} {
    margin-left: 0;
  }
`;

export const MobileFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;

  @media ${device.xs} {
    flex-direction: column;
  }
`;
