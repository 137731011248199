import React, { memo } from "react";
import { ListStyled } from "./style";
import Item from "./components/Item";
import Button from "components/Button";
import { useTranslation } from "react-i18next";

const List = () => {
  const { t } = useTranslation();

  return (
    <ListStyled>
      <Item title={t("Are there restrictions on how I use the music?")}>
        <div>{t("All of our music is copyright protected so you can’t")}:</div>
        <ol>
          <li>
            {t(
              "Release a Slash Audio song as your own on an album or streaming service."
            )}
          </li>
          <li>
            {t(
              "Copyright a final production that included a Slash Audio song."
            )}
          </li>
        </ol>
      </Item>
      <Item title={t("Can I release my production on iTunes, Spotify, etc.?")}>
        {t(
          "Yes. However, when using a distribution service like Tunecore, Distrokid or CD Baby, you must make sure that the release is not added to YouTube’s Content ID system. Contact your distributor to ensure that your release has opted out of Content ID."
        )}
      </Item>
      <Item
        title={t(
          "How can I remove copyright claims so I can monetize my videos?"
        )}
      >
        {t(
          "First, make a donation to get your commercial license and then just contact us with the video URL and we will have the claim removed right away."
        )}
      </Item>
      <Item title={t("Is the license royalty free?")}>
        {t(
          "Yes. Once a project is licensed you will never be asked to pay royalties in the future."
        )}
      </Item>
      <Item title={t("How do I credit Slash Audio in my video?")}>
        {t(
          "If you made a donation then you can skip giving credit. Although, we always appreciate the love."
        )}{" "}
        <br />
        {t(
          "If you are using the music for free then enter the following line in the YouTube video description or website text"
        )}
        : <br />
        <i>
          {t("Free music from")}{" "}
          <a href="https://www.slashaudio.com" target="_blank" rel="noopener">
            www.slashaudio.com
          </a>
        </i>
      </Item>
      <Item title={t("Can I use the music if I don’t make a donation?")}>
        {t(
          "Absolutely! As long as the project isn’t for the purposes of making money (including monetization), promoting a business, selling a product, or a work-for-hire then you can use the music all day long for free. Be sure to read the"
        )}{" "}
        <b>{t("Non-Commercial")}</b> {t("License section above.")}
      </Item>
      <Item
        title={t(
          "My project is non-commerical but I want to support you guys anyways. Can I make a donation?"
        )}
      >
        {t(
          "For sure! We work hard to make sure everyone has great music for their projects, regardless of whether they can pay or not, and we greatly appreciate our partners who help us finance this project."
        )}
      </Item>
      <Item title={t("Is there a donation minimum?")}>
        {t(
          "No. Budgets come in all sizes and we get that. So, we don’t want you to pass on good music because funds are tight. Of course, when you donate, we ask that you consider the cost and effort we go to to provide you with quality music and the value it gives to your work. It’s up to you."
        )}
      </Item>
      <Item
        title={t(
          "My project is not for commercial use now but I may want to monetize or sell it in the future. Can I use it for free until I decide?"
        )}
      >
        {t(
          "Yes! If you are initially releasing the project for non-commercial use then you don’t need to donate now. If later you decide to generate money from the project then you can donate at that time and get your license."
        )}
      </Item>
      <Item title={t("How can I donate?")}>
        {t("We accept donations through our page")}
        <Button className="donate-button" width={100} black left to="/donation">
          {t("donate")}
        </Button>
      </Item>
      <Item
        title={t(
          "Does a Commercial License allow me to use a song multiple times?"
        )}
      >
        {t(
          "One song license covers one project. This doesn’t necessarily mean one video. For example, if you are using a song for your podcast intro or channel theme and the music will be used multiple times in a number of videos, you only need one song license = one donation."
        )}{" "}
        <br />
        {t(
          "If you get a license for one song in a project and later want to use the same song in a completely different project, for example, another client or channel, you will need to donate for another commercial license for that project."
        )}
      </Item>
      <Item
        title={t(
          "Can I edit the music, loop it, stretch it, speed it up, etc.?"
        )}
      >
        {t(
          "Absolutely. You can do whatever you need to make the track fit your project."
        )}
      </Item>
      <Item title={t("Do I receive a license document when I donate?")}>
        {t(
          "Yes, a license will be emailed to you. Once you have the license just contact us with your Youtube video details and we will make sure copyright claims are removed so you can monetize your video."
        )}
      </Item>
      <Item
        title={t(
          "Does a copyright claim negatively affect my YouTube channel?"
        )}
      >
        {t(
          "No. A copyright claim is simply YouTube’s way of recognizing copyrighted music in videos. If you use our music for free then you may see a copyright claim notice on your video, but do not fear, we will not take any action to remove or mute the video. Ads may run before the video and this helps us manage the costs of providing the music for free. So, all you need to do is credit Slash Audio in the video description (see above), leave the claim undisputed and ignore it. That’s it."
        )}{" "}
        <br />
        {t(
          "If you would like the claim removed you can make a donation of any amount you can afford to Slash Audio and send us a link to the video. We will get it cleared right away."
        )}
      </Item>
      <Item
        title={t("I’m a student. Can I use the music for my school projects?")}
      >
        {t(
          "Yes. You can use the music for free for any of your school projects. If later on you decide to use the video for commercial purposes you can donate and get a license."
        )}
      </Item>
    </ListStyled>
  );
};

export default memo(List);
