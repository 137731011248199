import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const LogoStyled = styled(NavLink)`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  padding: 20px 25px;
`;
