import styled from "styled-components";

export const SocialIconStyled = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 100%;
  background: black;
  box-sizing: border-box;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 10px;
  }
`;
