import React, { memo } from "react";
import { ErrorsStyled, Error } from "./style";

const Errors = ({ list }) => {
  return (
    <ErrorsStyled>
      {list &&
        !!list.length &&
        list.map((error, i) => <Error key={i}>{error}</Error>)}
    </ErrorsStyled>
  );
};

export default memo(Errors);
