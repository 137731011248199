import React, { memo, useState, useCallback } from "react";
import { Title, ClaimButton, SuccessText } from "./style";
import Input from "components/Form/Input";
import Errors from "components/Errors";
import { useTranslation } from "react-i18next";

import IconSrc from "icons/website.svg";

const Form = ({ id }) => {
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);
  const { t } = useTranslation();

  const onChangeUrl = useCallback(
    (e) => {
      setUrl(e.target.value);
    },
    [url]
  );

  const onClickHandler = useCallback(() => {
    setLoading(true);
    axios
      .post(`/youtube_claim_release_requests`, {
        license_id: id,
        video_url: url,
      })
      .then(() => {
        setSuccess(true);
      })
      .catch(({ response }) => {
        const errors = Object.values(response.data.errors);
        if (errors.length) {
          setErrors(errors);
        }
      })
      .finally(() => setLoading(false));
  }, [url, id]);

  if (success) {
    return (
      <SuccessText>{t("All claims will be removed in 3 days")}</SuccessText>
    );
  }

  return (
    <>
      <Title>
        {t(
          "Thanks for your donation! Once you upload your video to YouTube, simply enter your video's URL here to have the copyright claim removed."
        )}
      </Title>
      <Input
        icon={IconSrc}
        alt="url"
        placeholder="Link to your video"
        onChange={onChangeUrl}
        onPressEnter={onClickHandler}
      />
      <Errors list={errors} />
      <ClaimButton
        width={250}
        gradient
        onClick={onClickHandler}
        loading={loading}
      >
        {t("Remove claims")}
      </ClaimButton>
    </>
  );
};

export default memo(Form);
