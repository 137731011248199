import React, { memo, useCallback } from "react";
import { TagStyled } from "./style";

const Tag = ({ id, children, onClick }) => {
  const onClickHandler = () => {
    onClick(id);
  };

  return <TagStyled onClick={onClickHandler}>{children}</TagStyled>;
};

export default memo(Tag);
