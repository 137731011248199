import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import { device } from "responsive";

export const FooterStyled = styled.div`
  .donate-button {
    width: 100px;

    @media ${device.xxs} {
      margin-top: 10px;
      margin-bottom: 10px;
      margin-right: 15px;
    }
  }
`;

export const Bottom = styled.div`
  padding: 20px;
  text-align: center;
  font-size: 14px;
  color: #666666;
  background: #e4e4e4;
  border-top: 1px solid #dbdbdb;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 60px 0;

  @media ${device.xxs} {
    margin: 30px auto;
    flex-direction: column;
  }
`;

export const Link = styled(NavLink)`
  display: block;
  text-decoration: none;
  color: #666666;
  font-size: 14px;
  margin-bottom: 7px;

  @media ${device.xxs} {
    display: inline-block;
    margin-right: 15px;
  }
`;
