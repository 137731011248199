import { createStore, applyMiddleware, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import globalReducer from "./reducers/global";
import playerReducer from "./reducers/player";

const rootReducer = combineReducers({
  global: globalReducer,
  player: playerReducer,
});

export function configureClientStore(state) {
  const composeEnhancers = composeWithDevTools({});
  return createStore(
    rootReducer,
    state,
    composeEnhancers(applyMiddleware(thunkMiddleware))
  );
}
