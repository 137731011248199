import React, { memo } from "react";
import { CloseButtonStyled } from "./style";
import Icon from "components/Icon";

import CloseIcon from "icons/close.svg";

const CloseButton = ({ ...props }) => {
  return (
    <CloseButtonStyled {...props}>
      <Icon src={CloseIcon} alt="close modal" />
    </CloseButtonStyled>
  );
};

export default memo(CloseButton);
