import React, { memo } from "react";
import { FilterSelectStyled } from "./style";
import Select from "components/Select";
import SelectItem from "components/Select/components/SelectItem";
import { useTranslation } from "react-i18next";

const FilterSelect = ({ onChange }) => {
  const { t } = useTranslation();

  return (
    <FilterSelectStyled>
      <Select
        defaultValue="popular"
        defaultTitle={t("popular")}
        onChange={onChange}
      >
        <SelectItem id="popular">{t("popular")}</SelectItem>
        <SelectItem id="downloads">{t("downloads")}</SelectItem>
      </Select>
    </FilterSelectStyled>
  );
};

export default memo(FilterSelect);
