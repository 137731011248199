import React from "react";
import { BrowserRouter, StaticRouter } from "react-router-dom";
import { Provider } from "react-redux";
import App from "components/App";
import { configureClientStore } from "redux/configureStore";
import GlobalPlayer from "components/GlobalPlayer";
import "i18n";
import axios from "axios";

if (typeof window !== "undefined") {
  const csrfToken = document.querySelector("meta[name=csrf-token]").content;
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  window.axios = axios;
}

const Main = ({ path, stripe_key, gtag_key }) => {
  const Router = typeof window !== "undefined" ? BrowserRouter : StaticRouter;
  const store = configureClientStore({
    global: {
      info: {
        stripe_key: stripe_key,
        gtag_key: gtag_key,
      },
    },
  });

  return (
    <Router location={path} context={{}}>
      <Provider store={store}>
        <GlobalPlayer>
          <App />
        </GlobalPlayer>
      </Provider>
    </Router>
  );
};

export default Main;
