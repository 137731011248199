import React, { memo } from "react";
import { useMobile } from "hooks";
import { size } from "responsive";

const IsMobile = ({ width = size.xs, children }) => {
  const mobile = useMobile(width);
  return mobile ? children : null;
};

export default memo(IsMobile);
