import styled from "styled-components";
import { device } from "responsive";

import BackgroundImage from "images/spectrum.png";

export const BannerStyled = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("${BackgroundImage}"),
    linear-gradient(
      90deg,
      rgba(253, 226, 100, 1) -5%,
      rgba(208, 18, 46, 1) 26%,
      rgba(33, 63, 131, 1) 85%,
      rgba(253, 226, 100, 1) 130%
    );
`;

export const Title = styled.div`
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
  font-size: 60px;
  color: white;

  @media ${device.md} {
    font-size: 50px;
  }

  @media ${device.xxs} {
    font-size: 35px;
  }
`;

export const Text = styled.div`
  font-size: 14px;
  color: white;
  margin-top: 20px;
  margin-bottom: 25px;
`;

export const Block = styled.div`
  max-width: 635px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 90px 15px 50px 15px;
  box-sizing: border-box;
  text-align: center;

  @media ${device.xxs} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;
