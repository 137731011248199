import React, { memo, useState, useCallback, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { TrackStyled } from "./style";
import { Left, Right, Center, Title, Time, Icons, Close } from "./style";
import PlayButton from "components/Track/components/PlayButton";
import ShareIcon from "components/Track/components/ShareIcon";
import DownloadIcon from "components/Track/components/DownloadIcon/versions/Global";
import VolumeBar from "./components/VolumeBar";
import {
  play,
  pause,
  closePlayer,
  changeProgressHistory,
  playNext,
} from "redux/actions/player";
import { useDuration } from "hooks";
import Arrows from "./components/Arrows";
import ReactAudioPlayer from "react-audio-player";
import Progress from "components/Track/components/Progress";
import IsDesktop from "components/IsDesktop";
import { size } from "responsive";
import CloseIconSrc from "icons/close.svg";

if (typeof window !== "undefined") {
  window.audios = [];
}

const Track = ({
  id,
  title,
  duration,
  isPlaying,
  cloudfront_url,
  seekTo,
  progressHistory,
  contextItems,
  dispatch,
}) => {
  const [volume, setVolume] = useState(0.5);
  const [progressSec, setProgressSec] = useState(0);
  const progress_time = useDuration(progressSec);
  const duration_time = useDuration(duration);

  const onChangeVolumeHandler = useCallback((value) => {
    setVolume(value);
  }, []);

  const onClickPlayHandler = useCallback(() => {
    isPlaying ? dispatch(pause()) : dispatch(play(id));
  }, [isPlaying, id]);

  const onCloseHandler = useCallback(() => {
    dispatch(closePlayer());
  }, []);

  useEffect(() => {
    Object.keys(audios).map((_id) => {
      audios[_id].pause();
    });
    if (audios[id]) {
      audios[id].currentTime = progressHistory * duration;
      audios[id].play();
      preload();
    }
  }, [cloudfront_url]);

  useEffect(() => {
    isPlaying ? audios[id].play() : audios[id].pause();
  }, [isPlaying]);

  useEffect(() => {
    audios[id].currentTime = seekTo * duration;
  }, [seekTo]);

  const timeupdate = () => {
    setProgressSec(audios[id].currentTime);
    const progress = audios[id].currentTime / duration;
    dispatch(changeProgressHistory(id, progress));
  };

  const onFinish = () => {
    dispatch(changeProgressHistory(id, 0));
    dispatch(pause());
    dispatch(playNext());
  };

  const preload = () => {
    contextItems.map((item, index) => {
      if (item.id == id) {
        if (contextItems[index + 1]) {
          addAudioToPreload(contextItems[index + 1]);
        }
      }
    });
  };

  const addAudioToPreload = (item) => {
    if (audios[item.id]) {
      audios[item.id].preload = "auto";
      audios[item.id].load();
    }
  };

  useEffect(() => {
    audios[id].addEventListener("timeupdate", timeupdate);
    audios[id].addEventListener("ended", onFinish);
    return () => {
      if (audios[id]) {
        audios[id].removeEventListener("timeupdate", timeupdate);
        audios[id].removeEventListener("ended", onFinish);
      }
    };
  }, [id]);

  const createRef = (audio, item) => {
    if (!audios[item.id]) {
      audios[item.id] = audio.audioEl.current;
    }
  };

  return (
    <TrackStyled>
      <Left>
        <PlayButton
          playing={isPlaying}
          className="play-button"
          onClick={onClickPlayHandler}
        />
        <div>
          <Title>{title}</Title>
        </div>
      </Left>
      <Center>
        <Arrows />
        <IsDesktop width={size.xs}>
          <Time>{progress_time}</Time>
          <Progress id={id} />
          <Time>{duration_time}</Time>
        </IsDesktop>
        {contextItems.map((item) => (
          <ReactAudioPlayer
            key={item.id}
            id={item.id + ""}
            src={item.cloudfront_url}
            ref={(el) => createRef(el, item)}
            volume={volume}
            preload="none"
          />
        ))}
        <VolumeBar volume={volume} onChange={onChangeVolumeHandler} />
      </Center>
      <Right>
        <Icons>
          <ShareIcon trackId={id} />
          <DownloadIcon url={cloudfront_url} id={id} />
        </Icons>
        <Close
          src={CloseIconSrc}
          onClick={onCloseHandler}
          alt="Close"
          title="Close"
        />
      </Right>
    </TrackStyled>
  );
};

function mapStateToProps(state) {
  const currentTracks = state.player.items.filter(
    (item) => item.id == state.player.currentId
  );
  if (!currentTracks.length) {
    return {};
  }
  const track = currentTracks[0];
  return {
    id: +track.id,
    title: track.title,
    duration: track.duration,
    isPlaying: state.player.playing,
    cloudfront_url: track.cloudfront_url,
    global: state.global.info,
    seekTo: state.player.seekTo,
    progressHistory: state.player.history[state.player.currentId] || 0,
    contextItems: state.player.items,
  };
}

export default connect(mapStateToProps)(memo(Track));
