import styled, { css } from "styled-components";
import { device } from "responsive";

export const ItemStyled = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid #d9d9d9;
  padding: 20px 25px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const Left = styled.div``;

export const Center = styled.div`
  flex: 1 1 100%;
  min-width: 0;
`;

export const Right = styled.div``;

export const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 5px;

  @media ${device.md} {
    font-size: 20px;
  }

  @media ${device.xxs} {
    font-size: 16px;
  }
`;

export const Description = styled.div`
  font-size: 14px;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.8;

  ${(props) =>
    props.open &&
    css`
      white-space: inherit;
    `}
`;
