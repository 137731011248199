import React, { memo } from "react";
import {
  Row,
  TextBlock,
  Title,
  Text,
  Number,
  IconImage,
  FooterBlock,
  DonateButton,
  Footer,
  MobileFooter,
} from "./style";
import { Container } from "components/Grid";
import IsDesktop from "components/IsDesktop";
import IsMobile from "components/IsMobile";
import { size } from "responsive";
import { useTranslation } from "react-i18next";
import Meta from "components/Meta";

import LogoSrc from "images/logo.png";
import NumberImageSrc from "images/12.svg";
import AboutUsImageSrc from "images/about_us.png";

const AboutUsPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Meta>
        <title>Download Free Music for Video | Slash Audio</title>
        <meta
          name="description"
          content="Free downloads of royalty free music for any project, including video, YouTube, TV, film, podcasts, and more. Commercial licenses available."
        />
        <meta
          name="keywords"
          content="catalog, royalty free, music free to use, video, YouTube, commercial license"
        />
        <meta
          property="og:title"
          content="Download Free Music for Video | Slash Audio"
        />
        <meta property="og:url" content={LogoSrc} />
      </Meta>

      <Row>
        <TextBlock>
          <Title>{t("Thanks for checking out Slash Audio")}</Title>
          <Text>
            {t(
              "My name is Leland Hawk and I am the composer and creator behind Slash Audio. For over 12 years I have been in the commercial music industry. During that time I have been able to put together a catalog of high quality music that is perfect for use in film/video, podcasts, ads and any other creative project you can think of."
            )}
          </Text>
        </TextBlock>
        <Number src={NumberImageSrc} alt="12 years" />
      </Row>
      <Row>
        <IconImage src={AboutUsImageSrc} alt="about us" />
        <TextBlock>
          <Title>{t("For creators")}</Title>
          <Text>
            {t(
              "As a creator, I believe that other creators should not be forced to compromise on their work because of tight budgets. So, I created Slash Audio to provide everyone with a free alternative for high quality production music in their non-commercial projects. I am able to keep this going with your help in spreading the word about Slash Audio. So, if you get some use from my music I ask that you share the links to my website and YouTube channel."
            )}
          </Text>
        </TextBlock>
      </Row>
      <Container>
        <IsMobile width={size.sm}>
          <MobileFooter>
            <Title>{t("Support Us")}</Title>
            <DonateButton to="/donation" gradient left>
              {t("donate")}
            </DonateButton>
          </MobileFooter>
        </IsMobile>
        <Footer>
          <IsDesktop width={size.sm}>
            <FooterBlock>
              <Title>{t("Support Us")}</Title>
              <DonateButton to="/donation" gradient left>
                {t("donate")}
              </DonateButton>
            </FooterBlock>
          </IsDesktop>
          <FooterBlock>
            <Text>
              {t(
                "Recently, many creators working on commercial projects have wanted to use my music and are not able to share my links. So, I offer a full commercial license to those who wish to make a small donation. You decide how much you have to give and I will issue you a universal license for commercial use."
              )}
            </Text>
          </FooterBlock>
          <FooterBlock>
            <Text>
              {t(
                "I truly appreciate the support that I continue to receive - you guys are great. It goes a long way in helping me keep the doors open and creating more great music for everyone."
              )}
            </Text>
          </FooterBlock>
        </Footer>
      </Container>
    </>
  );
};

export default memo(AboutUsPage);
