import React, { memo } from "react";
import { MenuIconStyled } from "./style";
import Icon from "components/Icon";

const MenuIcon = (props) => {
  const src = require("icons/menu.svg");

  return (
    <MenuIconStyled {...props}>
      <Icon src={src} alt="Burger" />
    </MenuIconStyled>
  );
};

export default memo(MenuIcon);
