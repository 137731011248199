import React, { memo, useEffect } from "react";
import { Route, useLocation, Switch } from "react-router-dom";
import { ContentStyled } from "./style";
import HomePage from "pages/Home";
import ContactsPage from "pages/Contacts";
import FAQPage from "pages/FAQ";
import AboutUsPage from "pages/AboutUs";
import DonationPage from "pages/Donation";
import TermsPage from "pages/Terms";
import PrivacyPage from "pages/Privacy";
import LicensePage from "pages/License";
import NotFoundPage from "pages/NotFound";
import { connect } from "react-redux";

const Content = ({ gtag_key }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    gtag("config", gtag_key, { page_path: location.pathname });
  }, [location]);

  return (
    <ContentStyled>
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/contacts" component={ContactsPage} />
        <Route path="/faq" component={FAQPage} />
        <Route path="/about-us" component={AboutUsPage} />
        <Route path="/donation" component={DonationPage} />
        <Route path="/term-of-use" component={TermsPage} />
        <Route path="/privacy-policy" component={PrivacyPage} />
        <Route path="/licenses/:id" component={LicensePage} />
        <Route component={NotFoundPage} />
      </Switch>
    </ContentStyled>
  );
};

function mapStateToProps(state) {
  return {
    gtag_key: state.global.info.gtag_key,
  };
}

export default connect(mapStateToProps)(memo(Content));
