import React, { memo } from "react";
import { FooterStyled, Bottom, Row, Link } from "./style";
import { Container } from "components/Grid";
import Logo from "./components/Logo";
import Block from "./components/Block";
import Button from "components/Button";
import SocialIcon from "./components/SocialIcon";
import IsDesktop from "components/IsDesktop";
import { useTranslation } from "react-i18next";

import TwitterIconSrc from "icons/twitter_w.svg";
import FacebookIconSrc from "icons/facebook.svg";
import YouTubeIconSrc from "icons/youtube.svg";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <FooterStyled>
      <Container>
        <Row>
          <IsDesktop>
            <Logo />
          </IsDesktop>
          <Block title={t("support")}>
            <Button
              className="donate-button"
              width={100}
              black
              left
              to="/donation"
            >
              {t("donate")}
            </Button>
          </Block>
          <Block title={t("quick links")}>
            <Link to="/about-us">{t("About Us")}</Link>
            <Link to="/faq">{t("FAQ")}</Link>
            <Link to="/contacts">{t("contact")}</Link>
            <Link to="/term-of-use">{t("Terms")}</Link>
            <Link to="/privacy-policy">{t("Privacy policy")}</Link>
          </Block>
          <Block title={t("social")}>
            <SocialIcon
              src={YouTubeIconSrc}
              alt="Youtube"
              href="https://www.youtube.com/channel/UC0r3yHquHHAJVSvUbZeJ0Rw"
              target="_blank"
              rel="noopener"
            />
          </Block>
        </Row>
      </Container>
      <Bottom>{t("all rights reserved")}</Bottom>
    </FooterStyled>
  );
};

export default memo(Footer);
