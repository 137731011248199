import React, { memo, useState, useCallback, useEffect, useRef } from "react";
import { CategoriesWidgetStyled, CategoriesWrapper } from "./style";
import { Container } from "components/Grid";
import Item from "./components/Item";
import ShowAll from "./components/ShowAll";

const CategoriesWidget = ({ items, selectedItems, onChange }) => {
  const [expand, setExpand] = useState(false);
  const [widgetHeight, setWidgetHeight] = useState(0);
  const widgetRef = useRef();

  const onClickHandler = useCallback(
    (id) => {
      let items = [];
      if (!selectedItems.includes(id)) {
        items = [id];
      }
      onChange(items);
    },
    [selectedItems]
  );

  const onShowHandler = useCallback(() => {
    setExpand(true);
  }, []);

  const onHideHandler = useCallback(() => {
    setExpand(false);
  });

  useEffect(() => {
    const height = widgetRef.current.scrollHeight;
    setWidgetHeight(height);
  }, [items]);

  return (
    <CategoriesWidgetStyled
      expand={expand}
      ref={widgetRef}
      height={widgetHeight}
    >
      <Container>
        <CategoriesWrapper>
          <ShowAll
            onShow={onShowHandler}
            onHide={onHideHandler}
            expand={expand}
          />
          {items.map((item) => (
            <Item
              key={item.id}
              id={item.id}
              onClick={onClickHandler}
              active={selectedItems}
            >
              {item.name}
            </Item>
          ))}
        </CategoriesWrapper>
      </Container>
    </CategoriesWidgetStyled>
  );
};

export default memo(CategoriesWidget);
