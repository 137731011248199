import styled, { css } from "styled-components";
import Icon from "components/Icon";

export const MenuStyled = styled.div`
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  max-width: 480px;
  z-index: 999;
  visibility: visible;
  right: 0;
  max-width: 480px;
  transform: translateX(100%);
  transition: transform 0.5s cubic-bezier(0.21, 0.75, 0.21, 1.02);

  ${(props) =>
    props.open &&
    css`
      transform: translateX(0);
    `}
`;

export const Panel = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 30px 35px;
`;

export const CloseIcon = styled(Icon)`
  width: 25px;
  cursor: pointer;
`;
