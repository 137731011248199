import styled, { css } from "styled-components";
import Icon from "components/Icon";

export const SelectStyled = styled.div`
  position: relative;
  height: 100%;
`;

export const Popup = styled.div`
  position: absolute;
  visibility: hidden;
  transform: translateY(-30%) scale(0.7);
  opacity: 0;
  transition: all 0.15s cubic-bezier(0.24, 1.04, 0.49, 1.29);
  width: 100%;
  z-index: 9;
  box-sizing: border-box;
  border: 1px solid #cccccc;
  background: white;

  ${(props) =>
    !props.open &&
    css`
      transition: all 0.2s ease-in;
    `}

  ${(props) =>
    props.open &&
    css`
      visibility: visible;
      transform: translateY(5px) scale(1);
      opacity: 1;
    `}
`;

export const Input = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
  padding-left: 15px;
  font-size: 14px;
  border: 1px solid #cccccc;
  cursor: pointer;
  height: 100%;
  color: #666666;
`;

export const Caret = styled(Icon)`
  width: 15px;
  height: 15px;

  ${(props) =>
    props.open &&
    css`
      transform: rotate(180deg);
    `}
`;
