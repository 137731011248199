import React, { useCallback, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import InfoBanner from "components/Banner/versions/InfoBanner";
import CategoriesWidget from "components/CategoriesWidget";
import { Container } from "components/Grid";
import TrackList from "components/TrackList";
import Pagination from "components/Pagination";
import SelectedTagsContext from "contexts/selectedTagsContext";
import { useUrlParams } from "hooks";
import Meta from "components/Meta";

import LogoSrc from "images/logo.png";

const HomePage = () => {
  const [categories, setCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tracks, setTracks] = useState([]);
  const [page, setPage] = useState(1);
  const [countPages, setCountPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [context, setContext] = useState("");
  const history = useHistory();
  const urlParams = useUrlParams(history);
  const containerRef = useRef();

  useEffect(() => {
    let cancelToken = axios.CancelToken.source();
    axios
      .get("/categories", { cancelToken: cancelToken.token })
      .then(({ data: { data } }) => {
        setCategories(data);
      });

    return () => {
      cancelToken.cancel("Operation canceled due to new request.");
    };
  }, []);

  useEffect(() => {
    let cancelToken = axios.CancelToken.source();
    setLoading(true);
    axios
      .get("/tracks", {
        params: {
          category_ids: selectedTags,
          page: page,
          share_track: urlParams?.share_track,
        },
        cancelToken: cancelToken.token,
      })
      .then(({ data: { data, metadata } }) => {
        const countPages = ~~(metadata.count / metadata.items);
        setTracks(data);
        setCountPages(countPages);
        setLoading(false);
      });

    return () => {
      cancelToken.cancel("Operation canceled due to new request.");
    };
  }, [page, selectedTags, urlParams?.share_track]);

  useEffect(() => {
    let context = `home-tracks-${page}-` + selectedTags.join("-");
    setContext(context);
  }, [page, selectedTags]);

  const onSelectCategory = (items) => {
    setPage(1);
    setSelectedTags(items);
  };

  const onChangePage = useCallback((page) => {
    setPage(page);
    const offsetTop = containerRef.current.offsetTop - 100;
    window.scrollTo(0, offsetTop);
  }, []);

  return (
    <>
      <Meta>
        <title>Download Free Music for YouTube Now | Slash Audio</title>
        <meta
          name="description"
          content="Free music for use in any video, film, podcast, YouTube, and more. Personal and commercial use available. Download now!"
        />
        <meta
          name="keywords"
          content="Royalty free, stock music, film, video, podcasts, YouTube, commercial, free, download"
        />
        <meta
          property="og:title"
          content="Download Free Music for YouTube Now | Slash Audio"
        />
        <meta property="og:url" content={LogoSrc} />
      </Meta>

      <SelectedTagsContext.Provider value={{ onSelectCategory, selectedTags }}>
        <InfoBanner />
        <CategoriesWidget
          items={categories}
          selectedItems={selectedTags}
          onChange={onSelectCategory}
        />
        <Container ref={containerRef}>
          <TrackList list={tracks} contextId={context} loading={loading} />
          <Pagination count={countPages} page={page} onChange={onChangePage} />
        </Container>
      </SelectedTagsContext.Provider>
    </>
  );
};

export default HomePage;
