import styled from "styled-components";
import { device } from "responsive";

export const DownloadIconStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  margin-left: 15px;
  cursor: pointer;
  background: black;
  border-radius: 7px;

  @media ${device.xs} {
    width: 40px;
    height: 40px;
    margin-left: 10px;
  }

  @media ${device.xxs} {
    width: 35px;
    height: 35px;
  }

  img {
    width: 20px;
  }
`;
