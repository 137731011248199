import React, { memo } from "react";
import { FavIconStyled } from "./style";
import Icon from "components/Icon";

const FavIconBase = ({ icon, ...props }) => {
  return (
    <FavIconStyled {...props}>
      <Icon src={icon} alt="favourites" />
    </FavIconStyled>
  );
};

export default memo(FavIconBase);
