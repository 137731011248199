import styled from "styled-components";
import { device } from "responsive";

export const Title = styled.div`
  margin-top: 60px;
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: bold;

  @media ${device.xxs} {
    font-size: 30px;
  }
`;

export const Paragraph = styled.div`
  margin: 20px 0;
  font-size: 14px;
  line-height: 25px;

  a {
    color: #d5002c;
  }

  .donate-button {
    display: inline-block;
    margin-left: 15px;
  }
`;

export const Text = styled.div`
  font-size: 14px;
  color: #333;
  max-width: 810px;
  text-align: center;
  margin: 30px auto;
  line-height: 1.8;
  padding-left: 15px;
  padding-right: 15px;
`;

export const Line = styled.hr`
  margin-top: 30px;
  margin-bottom: 30px;
`;
