import React, {
  memo,
  createContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { SelectStyled, Popup, Input, Caret } from "./style";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import CaretIcon from "icons/caret.svg";

const SelectContext = createContext({});

const Select = ({ defaultValue, defaultTitle, children, onChange }) => {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(defaultValue);
  const [activeTitle, setActiveTitle] = useState(defaultTitle);

  const onClickInput = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    onChange(active);
  }, [active]);

  return (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={onClose}>
      <SelectStyled>
        <Input onClick={onClickInput}>
          {activeTitle}
          <Caret src={CaretIcon} alt="filter" open={open} />
        </Input>
        <Popup open={open}>
          <SelectContext.Provider
            value={{ active, setOpen, setActive, setActiveTitle }}
          >
            {children}
          </SelectContext.Provider>
        </Popup>
      </SelectStyled>
    </ClickAwayListener>
  );
};

export { SelectContext };

export default memo(Select);
