import React, { memo, useEffect, useState, useCallback } from "react";
import { LicenseContainer } from "./style";
import Banner from "components/Banner";
import Form from "./components/Form";
import DonateBlock from "./components/DonateBlock";
import { useTranslation } from "react-i18next";
import Meta from "components/Meta";

import LogoSrc from "images/logo.png";

const LicensePage = ({ match }) => {
  const [url, setUrl] = useState();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const licenseKey = match.params.id;

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/licenses/${licenseKey}`)
      .then(({ data }) => {
        if (data.youtube_claim_release_request) {
          setUrl(data.youtube_claim_release_request["video_url"]);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <Meta>
        <title>Remove YouTube Claims | Slash Audio</title>
        <meta
          name="description"
          content="Remove a YouTube copyright claim with a full commercial use music license from Slash Audio."
        />
        <meta
          name="keywords"
          content="YouTube, copyright claim, commercial use, music license"
        />
        <meta
          property="og:title"
          content="Remove YouTube Claims | Slash Audio"
        />
        <meta property="og:url" content={LogoSrc} />
      </Meta>

      <Banner title={t("REMOVING CLAIMS")} />
      <LicenseContainer>
        {!loading &&
          (url ? <DonateBlock url={url} /> : <Form id={licenseKey} />)}
      </LicenseContainer>
    </>
  );
};

export default memo(LicensePage);
