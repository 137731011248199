import React, { memo } from "react";
import Banner from "components/Banner";
import { Container } from "components/Grid";
import { Title, Paragraph, Text, Line } from "./style";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import Meta from "components/Meta";

import LogoSrc from "images/logo.png";

const TermsPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Meta>
        <title>Music License Terms | Slash Audio</title>
        <meta
          name="description"
          content="Free downloads of royalty free music for any project, including video, YouTube, TV, film, podcasts, and more. Commercial licenses available."
        />
        <meta name="keywords" content="royalty free music, license" />
        <meta property="og:title" content="Music License Terms | Slash Audio" />
        <meta property="og:url" content={LogoSrc} />
      </Meta>

      <Banner title={t("Terms")} />
      <Text>
        {t(
          "Thanks for choosing Slash Audio. We want our music to be the soundtrack for your next project and have two options for the use of our entire catalog. Read on to see which option is best for you."
        )}
      </Text>
      <Line color="#d9d9d9" noshade size="1" />
      <Container>
        <Title>1. {t("Non commercial")}</Title>
        <Paragraph>
          {t(
            "Our entire catalog is 100% free to use in any non-commercial project. Just download the music that works for you and use it as you please. “Non-commercial use” means that the final product is not a work-for-hire, commercial advertisement, TV or theater production and will not be monetized on any platform."
          )}{" "}
          <br />
          {t(
            "Videos posted to YouTube and/or Facebook may receive a copyrighted music notice but this will not have any negative effect on your channel or page. It is simply the system recognizing our music on the platform. Ads may appear on the video. Don’t be concerned about it. You can simply credit by entering the following line in the YouTube video description or website text and ignore the copyright notice"
          )}
          :
        </Paragraph>
        <Paragraph>
          {t("Free music from")}{" "}
          <a href="https://www.slashaudio.com">www.slashaudio.com</a>
        </Paragraph>
        <Paragraph>
          {t(
            "You can also help us out by subscribing to the Slash Audio YouTube channel and sharing our videos to your social media channels. This really helps us cover project costs and continue to provide free music to everyone."
          )}
        </Paragraph>

        <Title>2. {t("Commercial")}</Title>
        <Paragraph>
          {t(
            "If you need to use the music in a commercial project then we ask that you make a donation to Slash Audio so we can continue providing this service to our partners. Choose an amount you think is fair and fits your budget, we trust you. Just please consider the added value our music gives to your production."
          )}{" "}
          :)
        </Paragraph>
        <Paragraph>
          {t(
            "With a donation you are not required to credit us and are free to monetize the video yourself."
          )}
          <Button
            className="donate-button"
            width={100}
            black
            left
            to="/donation"
          >
            {t("donate")}
          </Button>
        </Paragraph>
      </Container>
    </>
  );
};

export default memo(TermsPage);
