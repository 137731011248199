import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { device } from "responsive";

export const LogoStyled = styled(NavLink)`
  height: 50px;

  @media ${device.md} {
    height: 40px;
  }
`;
