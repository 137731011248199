import styled from "styled-components";
import Button from "components/Button";
import { device } from "responsive";

export const Title = styled.div`
  text-align: center;
  margin: 50px 0;
`;

export const ClaimButton = styled(Button)`
  height: 45px;
  margin-top: 50px;
`;

export const SuccessText = styled.div`
  font-size: 30px;
  text-align: center;
  margin-top: 50px;

  @media ${device.xs} {
    font-size: 18px;
  }
`;
