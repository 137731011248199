import React, { memo } from "react";
import { LogoStyled } from "./style";
import Image from "components/Image";

const Logo = () => {
  const src = require("images/logo.png");

  return (
    <LogoStyled to="/">
      <Image src={src} alt="SlashAudio" />
    </LogoStyled>
  );
};

export default memo(Logo);
