import React, { memo } from "react";
import { createPortal } from "react-dom";
import Player from "./components/Player";

const GlobalPlayer = ({ children }) => {
  if (typeof window !== "undefined") {
    const rootElement = document.querySelector("#global-player");

    return (
      <>
        {children}
        {createPortal(<Player />, rootElement)}
      </>
    );
  }

  return <>{children}</>;
};

export default memo(GlobalPlayer);
