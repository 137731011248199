import styled, { css } from "styled-components";

export const LoaderStyled = styled.div`
  ${(props) =>
    props.width &&
    css`
      width: ${props.width}px;
      height: ${props.width}px;
      margin-left: auto;
      margin-right: auto;
    `}

  .MuiCircularProgress-root {
    width: 100% !important;
    height: 100% !important;

    ${(props) =>
      props.color &&
      css`
        color: ${props.color};
      `}
  }
`;
