import React, { memo } from "react";
import { ItemStyled } from "./style";
import Icon from "components/Icon";

const Item = ({ link, icon, onClick, title, ...props }) => {
  return (
    <ItemStyled href={link} onClick={onClick} title={title} {...props}>
      <Icon src={icon} />
    </ItemStyled>
  );
};

export default memo(Item);
