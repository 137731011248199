import styled from "styled-components";
import { device } from "responsive";

export const Content = styled.div`
  margin: 90px auto;
  max-width: 800px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;

  @media ${device.xs} {
    margin: 40px auto;
  }
`;

export const Row = styled.div`
  display: flex;
  margin-top: 50px;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

export const Text = styled.div`
  margin-top: 15px;
  font-size: 14px;
  line-height: 25px;
`;
