import React, { memo } from "react";
import { ItemStyled } from "./style";

const Item = ({ type, page, onClick, selected, disabled }) => {
  let text = page;

  switch (type) {
    case "previous":
      text = "<";
      break;
    case "next":
      text = ">";
      break;
    case "end-ellipsis":
      text = "...";
      break;
    case "start-ellipsis":
      text = "...";
      break;
  }

  const onClickHandler = (e) => {
    if (!disabled && type !== "end-ellipsis" && type !== "start-ellipsis") {
      onClick(e);
    }
  };

  return (
    <ItemStyled
      onClick={onClickHandler}
      selected={selected}
      disabled={disabled}
      isEllipsis={["end-ellipsis", "start-ellipsis"].includes(type)}
    >
      {text}
    </ItemStyled>
  );
};

export default memo(Item);
