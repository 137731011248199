export const size = {
  xxs: 480,
  xs: 767,
  sm: 991,
  md: 1199,
  lg: 2500,
};

export const device = {
  xxs: `(max-width: ${size.xxs}px)`,
  xs: `(max-width: ${size.xs}px)`,
  sm: `(max-width: ${size.sm}px)`,
  md: `(max-width: ${size.md}px)`,
  lg: `(max-width: ${size.lg}px)`,
};
