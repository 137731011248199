import styled from "styled-components";

export const LicenseContainer = styled.div`
  max-width: 670px;
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
`;
