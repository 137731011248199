import React, { PureComponent } from "react";
import { createPortal } from "react-dom";
import { ModalStyled, Window, Background } from "./style";

let rootElement = false;
if (typeof window !== "undefined") {
  rootElement = document.querySelector("#modal");
}

class Modal extends PureComponent {
  constructor(props) {
    super(props);
    if (rootElement) {
      this.modalEl = document.createElement("div");
    }
  }

  componentDidMount() {
    rootElement.appendChild(this.modalEl);

    this.keydownEvent = window.addEventListener("keydown", (e) => {
      if (e.keyCode === 27) {
        this.props.onClose();
      }
    });
  }

  componentWillUnmount() {
    rootElement.removeChild(this.modalEl);
    window.removeEventListener("keydown", this.keydownEvent);
  }

  render() {
    if (!rootElement) {
      return <div></div>;
    }

    const { open, onClose, children, width, zIndex } = this.props;
    return createPortal(
      <ModalStyled open={open} zIndex={zIndex}>
        <Window open={open} onClose={onClose} width={width}>
          {children}
        </Window>
        <Background open={open} onClick={onClose} />
      </ModalStyled>,
      this.modalEl
    );
  }
}

export default Modal;
