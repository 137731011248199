import styled from "styled-components";
import { device } from "responsive";

export const FilterStyled = styled.div`
  display: flex;

  @media ${device.xxs} {
    margin-top: 15px;
  }
`;

export const Box = styled.div`
  background: black;
  width: 35px;
  height: 35px;
  padding: 7px;
  margin-right: 10px;
  box-sizing: border-box;
`;
