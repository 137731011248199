import React, { memo } from "react";
import { BannerStyled, Title } from "./style";

const Banner = ({ title, children }) => {
  return (
    <BannerStyled>{title ? <Title>{title}</Title> : children}</BannerStyled>
  );
};

export default memo(Banner);
