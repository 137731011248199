import styled from "styled-components";
import { device } from "responsive";

export const Content = styled.div`
  margin: 90px auto;
  max-width: 800px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;

  @media ${device.xs} {
    margin: 40px auto;
  }
`;

export const Row = styled.div`
  display: flex;
  margin-top: 50px;
`;

export const Feedback = styled.div`
  display: flex;
  align-items: center;
  padding-left: 40px;
  font-size: 30px;

  @media ${device.md} {
    padding: 0;
    width: 100%;
    justify-content: center;
  }
`;
