import React, { memo } from "react";
import { InfoIconStyled } from "./style";
import Icon from "components/Icon";

const InfoIconBase = ({ icon, active, ...props }) => {
  return (
    <InfoIconStyled {...props}>
      <Icon src={icon} alt="info" />
    </InfoIconStyled>
  );
};

export default memo(InfoIconBase);
