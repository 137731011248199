import React, { memo, useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DownloadIconStyled } from "./style";
import Icon from "components/Icon";
import Downloader from "js-file-downloader";
import Loader from "components/Loader";
import { useUrlParams } from "hooks";

const DownloadIconBase = ({ id, icon, url, loaderColor, ...props }) => {
  const [downloading, setDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const history = useHistory();
  const urlParams = useUrlParams(history);

  useEffect(() => {
    if (urlParams?.share_track == id && urlParams?.download == "true") {
      onClickHandler();
      const search = history.location.search.replace("&download=true", "");
      history.push({ ...history.location, search });
    }
  }, [urlParams]);

  const process = (event) => {
    if (!event.lengthComputable) return;
    const downloadingPercentage = Math.floor(
      (event.loaded / event.total) * 100
    );
    setDownloadProgress(downloadingPercentage);
  };

  const incrementDownloadCount = () => {
    axios.post(`/tracks/${id}/download`);
  };

  const onClickHandler = useCallback(() => {
    setDownloading(true);
    incrementDownloadCount();
    new Downloader({
      url: url,
      forceDesktopMode: true,
      process: process,
    }).finally(() => setDownloading(false));
  }, [url]);

  return (
    <DownloadIconStyled onClick={onClickHandler} {...props}>
      {downloading ? (
        <Loader progress={downloadProgress} width={20} color={loaderColor} />
      ) : (
        <Icon src={icon} alt="download" />
      )}
    </DownloadIconStyled>
  );
};

export default memo(DownloadIconBase);
