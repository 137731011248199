import React, { memo } from "react";
import { ArrowIconStyled } from "./style";

const ArrowIcon = () => {
  return (
    <ArrowIconStyled>
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 364.065 364.065"
      >
        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0" stopColor="rgba(233,156,84,1)"></stop>
          <stop offset="26%" stopColor="rgba(208,18,46,1)"></stop>
          <stop offset="85%" stopColor="rgba(33,63,131,1)"></stop>
          <stop offset="130%" stopColor="rgba(104,116,118,1)"></stop>
        </linearGradient>
        <g style={{ fill: "url(#gradient)" }}>
          <path
            d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
                    c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
                    C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
                    C255,161.018,253.42,157.202,250.606,154.389z"
          />
        </g>
      </svg>
    </ArrowIconStyled>
  );
};

export default memo(ArrowIcon);
