import styled from "styled-components";
import { Container } from "components/Grid";

export const Date = styled.div`
  margin: 15px 0;
`;

export const PrivacyContainer = styled(Container)`
  margin-top: 50px;
`;

export const Paragraph = styled.div`
  margin: 15px 0;
`;

export const Text = styled.div`
  a {
    color: #d5002c;
    overflow-wrap: break-word;
  }
`;
