import styled, { css } from "styled-components";

export const PlayerStyled = styled.div`
  background: white;
  border-top: 1px solid #d9d9d9;
  color: #333333;
  position: fixed;
  bottom: 0;
  z-index: 9999999999999;
  left: 0;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  transform: translateY(100%);
  transition: all 0.6s cubic-bezier(0.05, 1.2, 0.7, 0.95);

  ${(props) =>
    props.open &&
    css`
      transform: translateY(0);
    `}
`;
