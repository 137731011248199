import React, { memo, useState, useRef } from "react";
import {
  CarouselStyled,
  Item,
  ScrollPanel,
  LeftArrow,
  RightArrow,
  ScrollWrapper,
} from "./style";
import PropTypes from "prop-types";
import Slide from "./components/Slide";

let carousel = {};

const Carousel = ({ children, ...props }) => {
  const sliderRef = useRef();
  const [active, setActive] = useState(0);
  const [transLeft, setTransLeft] = useState(0);

  const onClickLeft = () => {
    const prevIndex = active - 1;
    const prevElement = carousel[prevIndex];
    if (prevElement) {
      moveSlider(prevElement, prevIndex);
    }
  };

  const onClickRight = () => {
    const nextIndex = active + 1;
    const nextElement = carousel[nextIndex];
    if (nextElement) {
      moveSlider(nextElement, nextIndex);
    }
  };

  const onChangeWidthHandler = (index, params) => {
    carousel[index] = params;
  };

  const moveSlider = (nextElement, index) => {
    const scrollWidth = sliderRef.current.scrollWidth;
    const sliderWidth = sliderRef.current.clientWidth;

    if (nextElement) {
      let leftOffset = -nextElement.offset;
      const maxLeft = nextElement.offset + sliderWidth;
      if (maxLeft < scrollWidth) {
        setActive(index);
        setTransLeft(leftOffset);
      } else {
        leftOffset = leftOffset - (scrollWidth - maxLeft);
        if (leftOffset !== transLeft) {
          setActive(index);
          setTransLeft(leftOffset);
        }
      }
    }
  };

  return (
    <CarouselStyled {...props}>
      <LeftArrow onClick={onClickLeft} />
      <ScrollWrapper>
        <ScrollPanel ref={sliderRef} left={transLeft}>
          {children.map((item, index) => (
            <Slide
              key={index}
              index={index}
              onChangeWidth={onChangeWidthHandler}
            >
              {item}
            </Slide>
          ))}
        </ScrollPanel>
      </ScrollWrapper>
      <RightArrow onClick={onClickRight} />
    </CarouselStyled>
  );
};

Carousel.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
};

export default memo(Carousel);
