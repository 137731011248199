import React, { memo } from "react";
import {
  NotFoundPageStyled,
  Block,
  Left,
  Right,
  Image404,
  Title,
  Text,
} from "./style";
import Logo from "./components/Logo";
import Button from "components/Button";
import { useTranslation, Trans } from "react-i18next";

import ImageSrc from "images/tumbleweed.svg";

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <NotFoundPageStyled>
      <Logo />
      <Block>
        <Left>
          <Image404 src={ImageSrc} alt="404" />
        </Left>
        <Right>
          <Title>404</Title>
          <Text>{t("The page you were looking for doesn’t exist")}</Text>
          <Button to="/" gradient width={160} left className="button">
            {t("Home page")}
          </Button>
        </Right>
      </Block>
    </NotFoundPageStyled>
  );
};

export default memo(NotFoundPage);
