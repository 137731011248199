import styled from "styled-components";

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 10px 30px 10px rgba(0, 0, 0, 0.2);
  height: 90px;
`;

export const Left = styled.div``;

export const Right = styled.div`
  display: flex;
  align-items: center;
`;
