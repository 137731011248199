import styled from "styled-components";
import { device } from "responsive";

export const TagsStyled = styled.div`
  font-size: 12px;
  color: #666666;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 210px;

  @media ${device.xxs} {
    width: 115px;
  }
`;
