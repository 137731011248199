import React, { memo } from "react";
import { TextareaStyled, Wrapper, FieldIcon, Field, Error } from "./style";

const Textarea = ({
  width,
  icon,
  onChange,
  placeholder,
  errors,
  value,
  alt,
}) => {
  return (
    <Wrapper>
      <Field width={width}>
        {icon && <FieldIcon src={icon} alt={alt} />}
        <TextareaStyled
          onChange={onChange}
          placeholder={placeholder}
          rows={8}
          value={value}
        />
      </Field>
      {errors &&
        !!errors.length &&
        errors.map((error, i) => <Error key={i}>{error}</Error>)}
    </Wrapper>
  );
};

export default memo(Textarea);
