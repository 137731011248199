import React, { memo } from "react";
import { DonateBlockStyled, Title, Link, Text, DonateButton } from "./style";
import { useTranslation } from "react-i18next";

const DonateBlock = ({ url }) => {
  const { t } = useTranslation();

  return (
    <DonateBlockStyled>
      <Title>
        {t("You have already used this attempt to remove claims from")}{" "}
        <Link href={url} target="_blank">
          {t("this video")}
        </Link>
      </Title>
      <Text>{t("Donate if you need another try.")}</Text>
      <DonateButton gradient width={150} to="/donation">
        {t("DONATE")}
      </DonateButton>
    </DonateBlockStyled>
  );
};

export default memo(DonateBlock);
