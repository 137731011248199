import React, { useState, useEffect, useLayoutEffect } from "react";
import { size } from "./responsive";

export function useWindowSize() {
  let width = 0;
  let height = 0;

  try {
    width = window.innerWidth;
    height = window.innerHeight;
  } catch {}

  const [size, setSize] = useState([width, height]);

  const updateSize = () => {
    setSize([window.innerWidth, window.innerHeight]);
  };

  useEffect(() => {
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

export function useMobile(width = size.sm) {
  const [mobile, setMobile] = useState(false);
  const windowSize = useWindowSize();

  useEffect(() => {
    const windowWidth = windowSize[0];
    if (windowWidth) {
      setMobile(windowWidth <= +width);
    }
  }, [windowSize]);

  return mobile;
}

export function useUrlParams(history) {
  const [params, setParams] = useState({});

  useEffect(() => {
    if (history.location.search) {
      const params = JSON.parse(
        '{"' +
          decodeURI(
            history.location.search
              .replace(/\?/g, "")
              .replace(/&/g, '","')
              .replace(/=/g, '":"')
          ) +
          '"}'
      );
      setParams(params);
    } else {
      setParams({});
    }
  }, [history.location.search]);

  return params;
}

export function useDuration(time) {
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    // Hours, minutes and seconds
    var hrs = ~~(time / 3600);
    var mins = ~~((time % 3600) / 60);
    var secs = ~~time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    setDuration(ret);
  }, [time]);

  return duration;
}
