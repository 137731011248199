import React, { memo, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  TrackStyled,
  Info,
  Left,
  Center,
  Right,
  Title,
  Time,
  Main,
  BackgroundBorder,
} from "./style";
import PlayButton from "./components/PlayButton";
import Progress from "./components/Progress";
import ShareIcon from "./components/ShareIcon";
import DownloadIcon from "./components/DownloadIcon/versions/Simple";
import IsDesktop from "components/IsDesktop";
import { size } from "responsive";
import { useDuration } from "hooks";
import { connect } from "react-redux";
import { play, pause } from "redux/actions/player";
import Tags from "./components/Tags";
import { useUrlParams } from "hooks";

const Track = ({
  id,
  title,
  duration,
  categories,
  onClickPlay,
  isPlaying,
  url,
  dispatch,
}) => {
  const duration_time = useDuration(duration);
  const history = useHistory();
  const urlParams = useUrlParams(history);

  const onClickPlayHandler = useCallback(() => {
    onClickPlay();
    isPlaying ? dispatch(pause()) : dispatch(play(id));
  }, [isPlaying]);

  return (
    <BackgroundBorder active={urlParams?.share_track == id}>
      <TrackStyled>
        <Main>
          <Left>
            <PlayButton playing={isPlaying} onClick={onClickPlayHandler} />
            <Info>
              <Title>{title}</Title>
              <Tags items={categories} />
            </Info>
          </Left>
          <IsDesktop width={size.xs}>
            <Center>
              <Progress id={id} />
              <Time>{duration_time}</Time>
            </Center>
          </IsDesktop>
          <Right>
            <ShareIcon trackId={id} title={title} />
            <DownloadIcon url={url} id={id} />
          </Right>
        </Main>
      </TrackStyled>
    </BackgroundBorder>
  );
};

function mapStateToProps(state, props) {
  return {
    isPlaying: state.player.playing && state.player.currentId == props.id,
  };
}

export default connect(mapStateToProps)(memo(Track));
