import styled, { css } from "styled-components";
import { device } from "responsive";

export const TrackStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  background: white;
`;

export const Main = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Info = styled.div`
  margin-left: 15px;
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
`;

export const Center = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
  width: 100%;
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  text-transform: uppercase;
  font-size: 16px;
  color: #333333;
  font-weight: bold;

  @media ${device.xxs} {
    text-transform: inherit;
    font-size: 14px;
  }
`;

export const Time = styled.div`
  font-size: 12px;
  color: #666666;
`;

export const BackgroundBorder = styled.div`
  padding: 5px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  ${(props) =>
    props.active &&
    css`
      background: linear-gradient(
        90deg,
        #f27c45 0%,
        #c21331 33.58%,
        #23357f 68.25%,
        #81917a 100%
      );
    `}

  ${(props) =>
    !props.active &&
    css`
      border: 1px solid #d9d9d9;
    `}
`;
