import React, { memo, useRef, useEffect } from "react";
import { TitleStyled } from "./style";

const Title = ({ children, onPositionUpdate, item }) => {
  const el = useRef();

  useEffect(() => {
    onPositionUpdate(item, el.current.offsetTop);
  }, []);

  return <TitleStyled ref={el}>{children}</TitleStyled>;
};

export default memo(Title);
