import styled from "styled-components";
import { device } from "responsive";

export const ItemStyled = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  flex-shrink: 0;

  @media ${device.xs} {
    cursor: inherit;
  }

  &:hover {
    background: #e4e4e4;
  }
`;
