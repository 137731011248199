import styled from "styled-components";
import Button from "components/Button";

export const ButtonStyled = styled(Button)`
  width: 150px;
  height: 45px;
  margin-left: 40px;
  margin-right: 40px;
  font-size: 16px;
`;
