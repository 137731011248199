import React, { memo } from "react";
import MetaTags from "react-meta-tags";

const Meta = ({ children }) => {
  const isSSR = typeof window == "undefined";

  if (isSSR) {
    return <head>{children}</head>;
  }

  return <MetaTags>{children}</MetaTags>;
};

export default memo(Meta);
