import styled from "styled-components";

export const SuccessModalStyled = styled.div`
  position: absolute;
  display: flex;
  padding: 10px;
  box-sizing: border-box;
  top: -10px;
  left: -200px;
  background: white;
  width: 300px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.16);
  transform: translateY(-100%);
  transition: opacity 0.3s ease-out;

  &.toast-enter {
    opacity: 0;
  }

  &.toast-enter.toast-enter-active {
    opacity: 1;
  }

  &.toast-leave {
    opacity: 1;
  }

  &.toast-leave.toast-leave-active {
    opacity: 0;
  }
`;

export const Left = styled.div`
  padding-top: 3px;
`;

export const Right = styled.div`
  width: calc(100% - 20px);
  padding-left: 10px;
  box-sizing: border-box;
`;

export const Title = styled.div`
  font-size: 14px;
  letter-spacing: 0;
  line-height: 25px;
`;

export const Text = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  color: #666666;
`;
