import styled from "styled-components";

export const SuccessPaymentModalStyled = styled.div`
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.16);
  background: white;
  width: 100%;
  padding: 60px;
  box-sizing: border-box;
`;

export const Title = styled.div`
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 53px;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
`;

export const Text = styled.div`
  text-align: center;
`;
