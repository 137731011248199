import styled, { css } from "styled-components";
import ArrowIcon from "icons/arrow.svg";
import { device } from "responsive";

export const CarouselStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

export const ScrollPanel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: no-wrap;
  width: 100%;
  height: 100%;
  transition: transform 0.5s cubic-bezier(0.21, 0.75, 0.21, 1.02);

  ${(props) =>
    props.left &&
    css`
      transform: translateX(${props.left}px);
    `}
`;

export const Item = styled.div`
  white-space: nowrap;
  flex-shrink: 0;
`;

export const Arrow = styled.div`
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-image: url("${ArrowIcon}");
  z-index: 9;
  cursor: pointer;

  @media ${device.xs} {
    cursor: inherit;
  }
`;

export const LeftArrow = styled(Arrow)``;

export const RightArrow = styled(Arrow)`
  transform: rotate(180deg);
`;

export const ScrollWrapper = styled.div`
  position: relative;
  height: 65px;
  flex: 1;
`;
