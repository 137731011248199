import React, { memo, useState, useCallback } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { useValidator } from "react-validate-fields";
import { validateRules, validateMessages } from "validator";
import { FormStyled, ButtonForm, Row, Left, Right } from "./style";
import { useTranslation } from "react-i18next";
import Input from "components/Form/Input";
import CardInput from "components/Form/Input/versions/CardInput";
import Errors from "components/Errors";
import SuccessPaymentModal from "components/Modal/versions/SuccessPayment";

import UserIcon from "icons/user.svg";
import MoneyIcon from "icons/money.svg";
import CardIcon from "icons/card.svg";
import LetterIcon from "icons/letter.svg";

const Form = () => {
  const [amount, setAmount] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();
  const { validate, errors, setErrors } = useValidator(
    validateRules,
    validateMessages
  );

  const options = {
    style: {
      base: {
        fontSize: "14px",
        fontFamily: "Exo, sans-serif",
      },
    },
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    const cardNumber = elements.getElement(CardNumberElement);
    const cardCvc = elements.getElement(CardCvcElement);
    const cardExpiry = elements.getElement(CardExpiryElement);
    validate({
      amount,
      email,
      name,
    })
      .then(() => {
        return axios.post(window.location.origin + "/stripe_payment_intents", {
          amount: amount,
          payer_name: name,
          payer_email: email,
        });
      })
      .then((response) => {
        return stripe.confirmCardPayment(response.data.stripe_client_secret, {
          payment_method: {
            card: cardNumber,
            billing_details: {
              name: name,
              email: email,
            },
          },
        });
      })
      .then((result) => {
        if (result.error) {
          setErrors({
            stripe: [result.error.message],
          });
        } else {
          setOpenSuccessModal(true);
          setAmount("");
          setName("");
          setEmail("");
          cardNumber.clear();
          cardCvc.clear();
          cardExpiry.clear();
        }
      })
      .catch((result) => {
        if (Object.keys(result.response.data?.errors).length) {
          const errors = result.response.data.errors;
          setErrors({
            amount: [errors?.amount],
            name: [errors?.payer_name],
            email: [errors?.payer_email],
          });
        }
      })
      .finally(() => setLoading(false));
  };

  const onChangeAmount = useCallback((e) => {
    setAmount(e.target.value);
  }, []);

  const onChangeName = useCallback((e) => {
    setName(e.target.value);
  }, []);

  const onChangeEmail = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  const onCloseModal = useCallback(() => {
    setOpenSuccessModal(false);
  }, []);

  return (
    <>
      <FormStyled>
        <Input
          icon={MoneyIcon}
          alt="amount"
          placeholder={t("Amount")}
          onChange={onChangeAmount}
          type="number"
          errors={errors["amount"]}
          value={amount}
        />
        <Input
          icon={UserIcon}
          alt="name"
          placeholder={t("your name") + "..."}
          onChange={onChangeName}
          errors={errors["name"]}
          value={name}
        />
        <Input
          icon={LetterIcon}
          alt="email"
          placeholder={t("your email") + "..."}
          onChange={onChangeEmail}
          errors={errors["email"]}
          value={email}
        />
        <CardInput
          icon={CardIcon}
          alt="card number"
          cardElement={<CardNumberElement options={options} />}
        />
        <Row>
          <Left>
            <CardInput cardElement={<CardExpiryElement options={options} />} />
          </Left>
          <Right>
            <CardInput cardElement={<CardCvcElement options={options} />} />
          </Right>
        </Row>
        <Errors list={errors["stripe"]} />
        <ButtonForm gradient center onClick={handleSubmit} loading={loading}>
          pay
        </ButtonForm>
      </FormStyled>

      <SuccessPaymentModal open={openSuccessModal} onClose={onCloseModal} />
    </>
  );
};

export default memo(Form);
