import React, { memo, useCallback } from "react";
import { Arrow } from "../../../Arrows/style";
import { connect } from "react-redux";
import { playPrev } from "redux/actions/player";
import Icon from "components/Icon";
import PrevIcon from "icons/prev.svg";

const Prev = ({ hasPrev, dispatch }) => {
  const onClickHandler = useCallback(() => {
    dispatch(playPrev());
  }, []);

  return (
    <Arrow title="Play previous track" onClick={onClickHandler}>
      {hasPrev && <Icon src={PrevIcon} alt="Play previous track" />}
    </Arrow>
  );
};

function mapStateToProps(state) {
  const currentId = state.player.currentId;
  const hasPrev = state.player.items.find((item, index) => {
    return item.id == currentId && state.player.items[index - 1];
  });

  return {
    hasPrev,
  };
}

export default connect(mapStateToProps)(memo(Prev));
