import React, { memo, useState, useCallback } from "react";
import { FormStyled, ButtonForm } from "./style";
import Input from "components/Form/Input";
import Textarea from "components/Form/Textarea";
import { useValidator } from "react-validate-fields";
import { validateRules, validateMessages } from "validator";
import { useTranslation } from "react-i18next";

import UserIcon from "icons/user.svg";
import LetterIcon from "icons/letter.svg";
import TelegramIcon from "icons/telegram.svg";

const Form = ({ onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const { validate, errors } = useValidator(validateRules, validateMessages);
  const { t } = useTranslation();

  const onChangeName = useCallback((e) => {
    setName(e.target.value);
  }, []);

  const onChangeEmail = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  const onChangeMessage = useCallback((e) => {
    setMessage(e.target.value);
  }, []);

  const onClickHandler = useCallback(() => {
    validate({ email, name, message })
      .then(() => {
        setLoading(true);
        axios
          .post("/feedbacks", {
            message: message,
            sender_email: email,
            sender_name: name,
          })
          .finally(() => {
            setLoading(false);
            setMessage("");
            setEmail("");
            setName("");
            onSubmit();
          });
      })
      .catch((err) => {});
  }, [name, email, message]);

  return (
    <FormStyled>
      <Input
        icon={UserIcon}
        alt="name"
        placeholder={t("your name") + "..."}
        onChange={onChangeName}
        errors={errors["name"]}
        value={name}
      />
      <Input
        icon={LetterIcon}
        alt="email"
        placeholder={t("your email") + "..."}
        onChange={onChangeEmail}
        errors={errors["email"]}
        value={email}
      />
      <Textarea
        icon={TelegramIcon}
        alt="message"
        placeholder={t("message")}
        onChange={onChangeMessage}
        errors={errors["message"]}
        value={message}
      />
      <ButtonForm gradient center loading={loading} onClick={onClickHandler}>
        {t("Send")}
      </ButtonForm>
    </FormStyled>
  );
};

export default memo(Form);
