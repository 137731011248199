import React, { memo } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { SuccessModalStyled, Left, Right, Title, Text } from "./style";
import Icon from "components/Icon";

import CheckIcon from "icons/check.svg";

const SuccessModal = ({ url, open }) => {
  return (
    <ReactCSSTransitionGroup
      transitionName="toast"
      transitionEnterTimeout={0}
      transitionLeaveTimeout={0}
    >
      {open && (
        <SuccessModalStyled>
          <Left>
            <Icon src={CheckIcon} alt="check" />
          </Left>
          <Right>
            <Title>Copied to clipboard</Title>
            <Text>{url}</Text>
          </Right>
        </SuccessModalStyled>
      )}
    </ReactCSSTransitionGroup>
  );
};

export default memo(SuccessModal);
