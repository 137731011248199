import styled from "styled-components";
import Button from "components/Button";
import { device } from "responsive";

export const FormStyled = styled.div`
  width: 100%;
  margin-top: 50px;

  @media ${device.sm} {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  @media ${device.xs} {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const ButtonForm = styled(Button)`
  font-size: 16px;
  height: 40px;
  margin-top: 15px;
`;

export const Row = styled.div`
  display: flex;
`;

export const Left = styled.div`
  width: 100%;
  margin-right: 40px;
`;

export const Right = styled.div`
  width: 100%;
`;
