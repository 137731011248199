import styled, { css } from "styled-components";
import { device } from "responsive";

export const ItemStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  min-height: 30px;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #cccccc;
  color: black;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;

  &:not(:last-child) {
    margin-right: 10px;
  }

  ${(props) =>
    props.selected &&
    css`
      background-color: black;
      border: 1px solid black;
      color: white;
    `}

  ${(props) =>
    props.isEllipsis &&
    css`
      border: none;
      padding: 0;
      align-items: flex-end;
      min-width: 15px;
    `}

  @media ${device.xxs} {
    padding: 10px 15px;
  }
`;
