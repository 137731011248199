import React, { memo, useState, useEffect, useCallback } from "react";
import { SlideStyled, Panel } from "./style";
import Item from "./components/Item";
import SuccessModal from "./components/SuccessModal";
import ShareModal from "components/Modal/versions/ShareEmail";

import FacebookIcon from "icons/fb.svg";
import TwitterIcon from "icons/twitter.svg";
import LinkedInIcon from "icons/linkedIn.svg";
import MailIcon from "icons/mail.svg";
import LinkIcon from "icons/link.svg";

const Slide = ({ open, trackId, title }) => {
  const [baseUrl, setBaseUrl] = useState("");
  const [shareText, setShareText] = useState("");
  const [facebookUrl, setFacebookUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [openShareModal, setOpenShareModal] = useState(false);
  const [openClipboard, setOpenClipboard] = useState(false);

  useEffect(() => {
    const url = `${window.location.origin}?share_track=${trackId}`;
    setBaseUrl(url);
  }, [trackId]);

  useEffect(() => {
    setShareText(`${title} ${baseUrl}`);
  }, [baseUrl]);

  useEffect(() => {
    setFacebookUrl(
      "https://www.facebook.com/sharer/sharer.php?u=" +
        encodeURIComponent(baseUrl)
    );
    setTwitterUrl(
      "https://twitter.com/intent/tweet?text=" + encodeURIComponent(shareText)
    );
    setLinkedinUrl(
      "https://www.linkedin.com/sharing/share-offsite/?url=" +
        encodeURIComponent(baseUrl)
    );
  }, [shareText, baseUrl]);

  const onEmailShareClick = useCallback(() => {
    setOpenShareModal(true);
  }, []);

  const onCloseShareModal = useCallback(() => {
    setOpenShareModal(false);
  }, []);

  const onCopyLinkClick = useCallback(() => {
    copyToClipboard(baseUrl);
    setOpenClipboard(true);
    setTimeout(() => setOpenClipboard(false), 1500);
  }, [baseUrl]);

  const copyToClipboard = (text) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    textarea.setSelectionRange(0, 99999);
    document.execCommand("copy");
    document.body.removeChild(textarea);
  };

  return (
    <>
      <SlideStyled open={open}>
        <Panel>
          <Item
            link={facebookUrl}
            icon={FacebookIcon}
            title="Share via Facebook"
            target="_blank"
            rel="noopener"
          />
          <Item
            link={twitterUrl}
            icon={TwitterIcon}
            title="Share via Twitter"
            target="_blank"
            rel="noopener"
          />
          <Item
            link={linkedinUrl}
            icon={LinkedInIcon}
            title="Share via LinkedIn"
            target="_blank"
            rel="noopener"
          />
          <Item
            onClick={onEmailShareClick}
            icon={MailIcon}
            title="Share via Email"
          />
          <Item onClick={onCopyLinkClick} icon={LinkIcon} title="Copy link" />
        </Panel>
      </SlideStyled>
      <SuccessModal url={baseUrl} open={openClipboard} />
      <ShareModal
        open={openShareModal}
        onClose={onCloseShareModal}
        trackId={trackId}
      />
    </>
  );
};

export default memo(Slide);
