import styled from "styled-components";
import { device } from "responsive";

export const Title = styled.div`
  font-size: 60px;
  color: white;

  @media ${device.md} {
    font-size: 50px;
  }

  @media ${device.xxs} {
    font-size: 35px;
  }
`;
