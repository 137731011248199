import styled from "styled-components";

export const MenuIconStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 90px;
  width: 90px;
  box-sizing: border-box;
  border-left: 1px solid #d2d2d2;
  cursor: pointer;

  img {
    width: 40px;
    height: 40px;
  }
`;
