import React, { memo } from "react";
import { LoaderStyled } from "./style";
import CircularProgress from "@material-ui/core/CircularProgress";

const Loader = ({ width, color, progress = 0 }) => {
  return (
    <LoaderStyled width={width} color={color}>
      <CircularProgress
        variant={progress ? "static" : "indeterminate"}
        value={progress}
      />
    </LoaderStyled>
  );
};

export default memo(Loader);
