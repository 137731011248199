import styled, { css } from "styled-components";
import Icon from "components/Icon";

export const Wrapper = styled.div`
  margin-bottom: 20px;
`;

export const Field = styled.div`
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #ccc;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px 0;

  ${(props) =>
    props.width &&
    css`
      max-width: ${props.width}px;
    `}
`;

export const TextareaStyled = styled.textarea`
  font-family: "Exo", sans-serif;
  width: 100%;
  padding: 0;
  font-family: Exo;
  font-size: 14px;
  margin-left: 10px;
  border: none;
  outline: none;
  resize: none;
`;

export const FieldIcon = styled(Icon)`
  width: 20px;
`;

export const Error = styled.div`
  font-size: 13px;
  color: red;
  text-align: right;
`;
