import React, { memo } from "react";
import Banner from "components/Banner";
import List from "./components/List";
import { Container } from "components/Grid";
import { useTranslation } from "react-i18next";
import Meta from "components/Meta";

import LogoSrc from "images/logo.png";

const FAQPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Meta>
        <title>Music Licensing Questions | Slash Audio</title>
        <meta
          name="description"
          content="Free downloads of royalty free music for any project, including video, YouTube, TV, film, podcasts, and more. Commercial licenses available."
        />
        <meta
          name="keywords"
          content="Royalty free, music, music library, license"
        />
        <meta
          property="og:title"
          content="Music Licensing Questions | Slash Audio"
        />
        <meta property="og:url" content={LogoSrc} />
      </Meta>

      <Banner title={t("Frequently Asked Questions")} />
      <Container>
        <List />
      </Container>
    </>
  );
};

export default memo(FAQPage);
