import React, { memo, useCallback, useState } from "react";
import Banner from "components/Banner";
import { Content, Row, Feedback } from "./style";
import Image from "./components/Image";
import Form from "./components/Form";
import IsDesktop from "components/IsDesktop";
import { size } from "responsive";
import { useTranslation } from "react-i18next";
import Meta from "components/Meta";

import LogoSrc from "images/logo.png";

const ContactsPage = () => {
  const [isSubmitForm, setIsSubmitForm] = useState(false);
  const { t } = useTranslation();

  const onSubmitForm = useCallback(() => {
    setIsSubmitForm(true);
    const timer = setTimeout(() => setIsSubmitForm(false), 4000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Meta>
        <title>Contact Us | Slash Audio</title>
        <meta
          name="description"
          content="Free music for use in any video, film, podcast, YouTube, and more. Personal and commercial use available. Download now!"
        />
        <meta name="keywords" content="Free, music, free music, license" />
        <meta property="og:title" content="Contact Us | Slash Audio" />
        <meta property="og:url" content={LogoSrc} />
      </Meta>

      <Banner title={t("contact us")} />
      <Content>
        <Row>
          <IsDesktop width={size.sm}>
            <Image />
          </IsDesktop>
          {isSubmitForm ? (
            <Feedback>Thank you, your message has been sent</Feedback>
          ) : (
            <Form onSubmit={onSubmitForm} />
          )}
        </Row>
      </Content>
    </>
  );
};

export default memo(ContactsPage);
