import React, { memo } from "react";
import { ItemStyled } from "./style";

const Item = ({ title, to, onClick }) => {
  return (
    <ItemStyled
      to={to}
      onClick={onClick}
      activeClassName="is-active"
      exact={true}
    >
      {title}
    </ItemStyled>
  );
};

export default memo(Item);
