import React, { memo, useContext, useCallback } from "react";
import { TagsStyled } from "./style";
import Tag from "./components/Tag";
import SelectedTagsContext from "contexts/selectedTagsContext";

const Tags = ({ items }) => {
  const { onSelectCategory, selectedTags } = useContext(SelectedTagsContext);

  const onClickHandler = (id) => {
    let items = [];
    if (!selectedTags.includes(id)) {
      items = [id];
    }
    onSelectCategory(items);
  };

  return (
    <TagsStyled>
      {items.map((item) => (
        <Tag id={item.id} key={item.id} onClick={onClickHandler}>
          {item.name}
        </Tag>
      ))}
    </TagsStyled>
  );
};

export default memo(Tags);
