import styled from "styled-components";

export const SelectItemStyled = styled.div`
  font-size: 14px;
  color: #666666;
  padding: 5px 15px;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid #cccccc;
  }
`;
