import React, { memo, useState } from "react";
import { TrackListStyled } from "./style";
import Track from "components/Track";
import { connect } from "react-redux";
import { changeContext } from "redux/actions/player";
import PreloadItem from "./components/PreloadItem";

const TrackList = ({ list, contextId, loading, dispatch }) => {
  const [preloadItems, setPreloadItems] = useState(new Array(10).fill(null));

  const onClickPlayHandler = () => {
    dispatch(changeContext(contextId, list));
  };

  if (loading) {
    return (
      <TrackListStyled>
        {preloadItems.map((item, i) => (
          <PreloadItem key={i} />
        ))}
      </TrackListStyled>
    );
  }

  return (
    <TrackListStyled>
      {list.map((track) => (
        <Track
          key={track.id}
          id={track.id}
          title={track.title}
          categories={track.categories}
          duration={track.duration}
          url={track.cloudfront_url}
          onClickPlay={onClickPlayHandler}
        />
      ))}
    </TrackListStyled>
  );
};

export default connect()(memo(TrackList));
