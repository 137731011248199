import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const ItemStyled = styled(NavLink)`
  display: flex;
  position: relative;
  text-decoration: none;
  height: 75px;
  padding-left: 80px;
  align-items: center;
  color: #000000;
  font-size: 30px;

  &:hover {
    color: #c41932;
  }

  &.is-active {
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 8px;
      height: 100%;
      background: #c41932;
    }
  }
`;
