import React, { memo, useCallback } from "react";
import { ShowAllStyled, ShowAllIcon, CloseIcon } from "./style";

import IconSrc from "icons/menu_small.svg";
import CloseSrc from "icons/close.svg";

const ShowAll = ({ expand, onShow, onHide }) => {
  const onClickHandler = useCallback(() => {
    expand ? onHide() : onShow();
  }, [expand]);

  return (
    <ShowAllStyled onClick={onClickHandler}>
      {expand ? (
        <>
          <CloseIcon src={CloseSrc} alt="expand" />
          <span>Collapse</span>
        </>
      ) : (
        <>
          <ShowAllIcon src={IconSrc} alt="show all" />
          <span>Show all</span>
        </>
      )}
    </ShowAllStyled>
  );
};

export default memo(ShowAll);
