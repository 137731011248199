import React, { memo, useMemo } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Banner from "components/Banner";
import { Content, Row, Title, Text } from "./style";
import Image from "./components/Image";
import Form from "./components/Form";
import IsDesktop from "components/IsDesktop";
import { size } from "responsive";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Meta from "components/Meta";

import LogoSrc from "images/logo.png";

const DonationPage = ({ stripe_key }) => {
  const { t } = useTranslation();
  const stripePromise = useMemo(() => loadStripe(stripe_key), [stripe_key]);

  return (
    <>
      <Meta>
        <title>Get a Commercial Music License| Slash Audio</title>
        <meta
          name="description"
          content="Get a full commercial use, royalty free music license and remove any copyright claim by making a small donation to Slash Audio."
        />
        <meta
          name="keywords"
          content="commercial use, royalty free, license, royalty free license"
        />
        <meta
          property="og:title"
          content="Get a Commercial Music License| Slash Audio"
        />
        <meta property="og:url" content={LogoSrc} />
      </Meta>

      <Banner title={t("DONATIONS")} />
      <Content>
        <Row>
          <IsDesktop width={size.sm}>
            <Image />
          </IsDesktop>
          <div>
            <Title>{t("Thanks for your support!")}</Title>
            <Text>
              {t(
                "When you make a donation to Slash Audio you will receive a license to use the music in any commercial project. If you only wish to help me keep this project going and don’t need a commercial license, let me know. Your support is very much appreciated."
              )}
            </Text>
            <Elements stripe={stripePromise}>
              <Form />
            </Elements>
          </div>
        </Row>
      </Content>
    </>
  );
};

function mapStateToProps(state) {
  return {
    stripe_key: state.global.info.stripe_key,
  };
}

export default connect(mapStateToProps)(memo(DonationPage));
