import styled from "styled-components";
import { device } from "responsive";
import Icon from "components/Icon";

export const TrackStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .play-button {
    background-color: black;
    border: none;
    margin-right: 15px;

    svg {
      fill: white;
    }
  }
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  max-width: 300px;
  width: 100%;
  flex-shrink: 0;

  @media ${device.xs} {
    max-width: 100%;
    flex-shrink: inherit;
    margin-right: 15px;
  }
`;

export const Center = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media ${device.xs} {
    width: auto;
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.h2`
  display: block;
  font-size: 16px;
  color: #333333;
  margin-bottom: 2px;
  margin-top: 0;
`;

export const CategoryName = styled.span`
  color: #787878;
  font-size: 14px;
`;

export const Time = styled.div`
  color: #787878;
  font-size: 14px;
`;

export const Icons = styled.div`
  display: flex;
  align-items: center;
  margin-left: 25px;
  margin-right: 25px;

  @media ${device.xs} {
    display: none;
  }
`;

export const Close = styled(Icon)`
  cursor: pointer;
  margin-left: 15px;
  margin-right: 25px;

  @media ${device.xs} {
    cursor: inherit;
  }
`;
