import React, { memo, useState, useRef, useEffect } from "react";
import { ProgressStyled, Line, GradientLine, ProgressLine } from "./style";
import { connect } from "react-redux";
import { seekTo, changeProgressHistory } from "redux/actions/player";

const Progress = ({ id, progress, isCurrent, dispatch }) => {
  const [width, setWidth] = useState(0);
  const lineRef = useRef();

  useEffect(() => {
    const onResize = () => {
      setWidth(lineRef.current.offsetWidth);
    };
    window.addEventListener("resize", onResize);
    onResize();
    return () => window.removeEventListener("resize", onResize);
  }, []);

  const onSeekHandler = (e) => {
    const currentProgressWidth = e.clientX - e.target.offsetLeft;
    const progress = currentProgressWidth / e.target.clientWidth;
    if (isCurrent) {
      dispatch(seekTo(progress));
    } else {
      dispatch(changeProgressHistory(id, progress));
    }
  };

  return (
    <ProgressStyled onClick={onSeekHandler}>
      <Line ref={lineRef}>
        <ProgressLine width={progress}>
          <GradientLine width={width} />
        </ProgressLine>
      </Line>
    </ProgressStyled>
  );
};

function mapStateToProps(state, props) {
  let progress = 0;

  if (state.player.history[props.id]) {
    progress = state.player.history[props.id] * 100;
  }

  return {
    progress: progress,
    isCurrent: state.player.currentId == props.id,
  };
}

export default connect(mapStateToProps)(memo(Progress));
