import React, { memo } from "react";
import { FilterStyled, Box } from "./style";
import Icon from "components/Icon";
import FilterSelect from "components/Select/versions/FilterSelect";

import FilterIconSrc from "icons/filter.svg";

const Filter = ({ onChange }) => {
  return (
    <FilterStyled>
      <Box>
        <Icon src={FilterIconSrc} alt="Filter" />
      </Box>
      <FilterSelect onChange={onChange} />
    </FilterStyled>
  );
};

export default memo(Filter);
