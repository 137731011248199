import styled from "styled-components";
import { device } from "responsive";

export const BlockStyled = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.xxs} {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
`;

export const Title = styled.div`
  font-size: 16px;
  text-transform: uppercase;
  color: #333333;
  font-weight: bold;
`;

export const Content = styled.div`
  flex: 1;
  margin-top: 35px;
  border-left: 1px solid #d0d0d0;
  padding-left: 15px;

  @media ${device.xxs} {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 15px;
  }
`;
