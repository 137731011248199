import React, { memo } from "react";
import Banner from "components/Banner";
import { Title } from "./style";
import { Text, Block } from "../../style";

const TextBanner = ({ title, children }) => {
  return (
    <Banner>
      <Block>
        <Title>{title}</Title>
        <Text>{children}</Text>
      </Block>
    </Banner>
  );
};

export default memo(TextBanner);
