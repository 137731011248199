import styled, { css } from "styled-components";

export const CategoriesWidgetStyled = styled.div`
  border-bottom: 1px solid #d4d4d4;
  max-height: 135px;
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0.16, 1, 0.3, 1);

  ${(props) =>
    props.expand &&
    css`
      max-height: ${props.height}px;
    `}

  .categories-carousel {
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      width: 50px;
      height: 100%;
      z-index: 5;
    }

    &:before {
      left: 0;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    &:after {
      right: 0;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 1) 100%
      );
    }
  }
`;

export const CategoriesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 25px -10px;
`;
