import styled from "styled-components";

export const ListStyled = styled.div`
  margin-top: 50px;

  .donate-button {
    display: inline-flex;
    margin-left: 15px;
  }
`;
