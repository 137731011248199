import React, { memo, useCallback } from "react";
import { AnchorStyled } from "./style";

const Anchor = ({ item, onClick, children }) => {
  const onClickHandler = () => {
    onClick(item);
  };

  return <AnchorStyled onClick={onClickHandler}>{children}</AnchorStyled>;
};

export default memo(Anchor);
