import React, { memo } from "react";
import { ButtonStyled } from "./style";

const Button = ({ children, to }) => {
  return (
    <ButtonStyled to={to} gradient>
      {children}
    </ButtonStyled>
  );
};

export default memo(Button);
