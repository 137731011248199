import React, { memo, useContext, useCallback, useEffect } from "react";
import { SelectItemStyled } from "./style";
import { SelectContext } from "../../index";

const SelectItem = ({ id, children }) => {
  const { active, setOpen, setActiveTitle, setActive } = useContext(
    SelectContext
  );

  const onClickHandler = useCallback(() => {
    setOpen(false);
    setActive(id);
    setActiveTitle(children);
  }, []);

  return (
    <SelectItemStyled
      onClick={onClickHandler}
      className={active == id && "active"}
    >
      {children}
    </SelectItemStyled>
  );
};

export default memo(SelectItem);
