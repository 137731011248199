import React, { memo, useCallback } from "react";
import { ItemStyled } from "./style";

const Item = ({ id, children, active, onClick }) => {
  const onClickHandler = useCallback(() => {
    onClick(id);
  }, [active]);

  return (
    <ItemStyled active={active.includes(id)} onClick={onClickHandler}>
      {children}
    </ItemStyled>
  );
};

export default memo(Item);
