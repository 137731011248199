import styled from "styled-components";
import { device } from "responsive";

export const ShareIconStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-left: 15px;
  cursor: pointer;

  @media ${device.xs} {
    margin-left: 10px;
  }

  &:hover {
    background: black;
    box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.16);
  }
`;
