import styled, { css } from "styled-components";
import { device } from "responsive";

export const CloseButtonStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  z-index: 1000;
  cursor: pointer;

  @media ${device.xs} {
    cursor: inherit;
  }
`;
