import React, { memo } from "react";
import { Title } from "./style";
import { Block, Text } from "../../style";
import Button from "components/Button";
import Banner from "components/Banner";
import { useTranslation } from "react-i18next";

const InfoBanner = () => {
  const { t } = useTranslation();

  return (
    <Banner>
      <Block>
        <Title>{t("your free music solution")}</Title>
        <Text>
          {t(
            "Slash Audio offers our entire catalog for use in any non-commercial project for free and a universal commercial license for a small donation to help support our project. We make it simple and free to make your project sound great."
          )}
        </Text>
        <Button width={120} white to="/about-us">
          {t("Learn More")}
        </Button>
      </Block>
    </Banner>
  );
};

export default memo(InfoBanner);
