import { rules } from "react-validate-fields";
import { MONEY_AMOUNT_REGEX } from "shared.js.erb";

export const validateRules = {
  name: {
    empty: (value) => !!value,
  },
  email: {
    isEmail: (value) => rules.email(value),
  },
  message: {
    empty: (value) => !!value,
  },
  amount: {
    isAmount: (value) => MONEY_AMOUNT_REGEX.test(value),
  },
};

export const validateMessages = {
  name: {
    empty: () => "Name is required",
  },
  email: {
    isEmail: (value) => "Email is not valid",
  },
  message: {
    empty: () => "Message is required",
  },
  amount: {
    isAmount: () => "Amount is not correct",
  },
};
