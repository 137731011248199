import React, { memo } from "react";
import { ImageStyled } from "./style";

const Image = () => {
  return (
    <ImageStyled>
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 364.065 364.065"
      >
        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0" stopColor="rgba(233,156,84,1)"></stop>
          <stop offset="26%" stopColor="rgba(208,18,46,1)"></stop>
          <stop offset="85%" stopColor="rgba(33,63,131,1)"></stop>
          <stop offset="130%" stopColor="rgba(104,116,118,1)"></stop>
        </linearGradient>
        <g style={{ fill: "url(#gradient)" }}>
          <path
            d="M349.927,138.375v0.16c0,0,0-0.48-0.48-0.64l-18.48-14.8v-55.6c0-7.776-6.304-14.08-14.08-14.08h-72.8l-58.48-46.88
                        c-2.196-1.766-5.324-1.766-7.52,0l-58.48,46.88h-72.8c-7.776,0-14.08,6.304-14.08,14.08v55.52l-18.48,14.8
                        c-0.206,0.225-0.393,0.465-0.56,0.72c-9.054,11.099-13.899,25.038-13.68,39.36v124.96c0,27.04,14.72,56,56,56h252
                        c27.04,0,56-14.72,56-56v-124.96C364.672,163.386,359.616,149.195,349.927,138.375z M331.047,138.295l4.64,3.76l-4.64,3.28
                        V138.295z M181.927,19.015l42.96,34.48h-85.92L181.927,19.015z M44.887,67.495c0-1.105,0.895-2,2-2h270.08c1.105,0,2,0.895,2,2
                        v86.4l-137.04,95.52l-137.04-95.52V67.495z M32.807,138.455v7.04l-4.64-3.44L32.807,138.455z M11.927,302.695v-124.88
                        c-0.072-9.529,2.503-18.89,7.44-27.04l120,83.76l-122.08,91.52C13.742,318.779,11.908,310.788,11.927,302.695z M308.007,346.775
                        l-252.16-0.08c-11.511,0.707-22.828-3.209-31.44-10.88l125.52-94l28.56,20.24h0.48h0.56c0.753,0.351,1.57,0.541,2.4,0.56
                        c0.83-0.019,1.647-0.209,2.4-0.56h0.56h0.48l28-19.52l125.52,93.76C330.132,343.285,319.208,346.992,308.007,346.775z
                        M351.847,177.895v124.88c0.253,8.332-1.62,16.591-5.44,24l-122.64-91.84l120.72-84.16
                        C349.661,158.847,352.23,168.314,351.847,177.895z
                        M215.767,92.295h-132c-3.336,0-6.04,2.704-6.04,6.04c0,3.336,2.704,6.04,6.04,6.04h132c3.336,0,6.04-2.704,6.04-6.04
                        C221.807,94.999,219.103,92.295,215.767,92.295z
                        M280.087,136.455H83.767c-3.336,0-6.04,2.704-6.04,6.04s2.704,6.04,6.04,6.04h196.32c3.336,0,6.04-2.704,6.04-6.04
                        S283.423,136.455,280.087,136.455z"
          />
        </g>
      </svg>
    </ImageStyled>
  );
};

export default memo(Image);
