import React, { memo, useCallback, useState } from "react";
import { HeaderStyled, Left, Right } from "./style";
import Logo from "./components/Logo";
import MenuIcon from "./components/MenuIcon";
import Menu from "./components/Menu";
import Button from "./components/Button";
import { size } from "responsive";
import IsDesktop from "components/IsDesktop";
import { useTranslation } from "react-i18next";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { t } = useTranslation();

  const onClickMenu = useCallback(() => {
    setMenuOpen(true);
  }, []);

  const onCloseMenu = useCallback(() => {
    setMenuOpen(false);
  }, []);

  return (
    <>
      <HeaderStyled>
        <Left>
          <Logo />
        </Left>
        <Right>
          <IsDesktop width={size.xxs}>
            <Button to="/donation">{t("donate")}</Button>
          </IsDesktop>
          <MenuIcon onClick={onClickMenu} />
        </Right>
      </HeaderStyled>

      <Menu open={menuOpen} onClose={onCloseMenu} />
    </>
  );
};

export default memo(Header);
