import styled, { css } from "styled-components";
import { device } from "responsive";

export const OpenIconStyled = styled.div`
  width: 50px;
  height: 50px;
  padding: 13px;
  box-sizing: border-box;
  background: black;
  border: 1px solid black;
  cursor: pointer;
  margin-left: 15px;
  margin-top: 5px;

  @media ${device.xxs} {
    width: 35px;
    height: 35px;
    padding: 8px;
  }

  ${(props) =>
    props.open &&
    css`
      background: white;
    `}
`;
