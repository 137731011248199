import React, { memo } from "react";
import { DownloadIconBaseStyled } from "./style";
import IconSrc from "icons/download_global.svg";

const DownloadIcon = (props) => {
  return (
    <DownloadIconBaseStyled icon={IconSrc} loaderColor="black" {...props} />
  );
};

export default memo(DownloadIcon);
