import styled, { css } from "styled-components";

export const ModalStyled = styled.div`
  display: flex;
  overflow-y: scroll;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 100px 15px;
  box-sizing: border-box;
  z-index: 9999;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ${(props) =>
    !props.open &&
    css`
      visibility: hidden;
      transition: visibility 0s ease-in 0.2s;
    `}

  ${(props) =>
    props.open &&
    css`
      visibility: visible;
    `}

    ${(props) =>
      props.zIndex &&
      css`
        z-index: ${props.zIndex};
      `}
`;

export const Window = styled.div`
  margin: auto;
  z-index: 1;
  transform: translate(0, -25%);
  opacity: 0;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);

  ${(props) =>
    props.open &&
    css`
      transform: translate(0, 0);
      opacity: 1;
    `}

  ${(props) =>
    props.width &&
    css`
      max-width: ${props.width}px;
      width: 100%;
    `}
`;

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0;
  transition: opacity 0.2s ease-in;

  ${(props) =>
    props.open &&
    css`
      opacity: 0.4;
    `}
`;
