import styled, { css } from "styled-components";
import { device } from "responsive";

export const ItemStyled = styled.div`
  display: inline-flex;
  font-family: "Exo", sans-serif;
  font-size: 14px;
  padding: 5px 10px;
  margin: 10px;
  text-align: center;
  cursor: pointer;
  color: #666666;

  &:hover {
    border-radius: 7px;
    background-color: rgb(196 25 50 / 0.08);
  }

  ${(props) =>
    props.active &&
    css`
      color: #bb1234;
    `}

  @media ${device.xs} {
    cursor: inherit;
  }
`;
