import React, { memo } from "react";
import { connect } from "react-redux";
import { PlayerStyled } from "./style";
import Track from "../Track";

const Player = ({ open, trackId }) => {
  return (
    <PlayerStyled open={open && trackId}>{trackId && <Track />}</PlayerStyled>
  );
};

function mapStateToProps(state) {
  return {
    trackId: state.player.currentId,
    open: state.player.open,
  };
}

export default connect(mapStateToProps)(memo(Player));
