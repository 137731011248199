import React, { memo } from "react";
import { ArrowsStyled } from "./style";
import Prev from "./components/Prev";
import Next from "./components/Next";

const Arrows = () => {
  return (
    <ArrowsStyled>
      <Prev />
      <Next />
    </ArrowsStyled>
  );
};

export default memo(Arrows);
