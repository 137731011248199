import React, { memo, useCallback } from "react";
import { Arrow } from "../../../Arrows/style";
import { connect } from "react-redux";
import { playNext } from "redux/actions/player";
import Icon from "components/Icon";
import NextIcon from "icons/next.svg";

const Next = ({ hasNext, dispatch }) => {
  const onClickHandler = useCallback(() => {
    dispatch(playNext());
  }, []);

  return (
    <Arrow title="Play next track" onClick={onClickHandler}>
      {hasNext && <Icon src={NextIcon} alt="Play next track" />}
    </Arrow>
  );
};

function mapStateToProps(state) {
  const currentId = state.player.currentId;
  const hasNext = !!state.player.items.find((item, index) => {
    return item.id == currentId && state.player.items[index + 1];
  });

  return {
    hasNext,
  };
}

export default connect(mapStateToProps)(memo(Next));
