import React, { memo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { ButtonStyled } from "./style";
import PropTypes from "prop-types";
import Loader from "components/Loader";

const Button = ({ loading, children, to, onClick, ...props }) => {
  const history = useHistory();

  const onClickHandler = useCallback(() => {
    if (!loading) {
      if (to) {
        history.push(to);
      }
      if (onClick) {
        onClick();
      }
    }
  }, [history, to, onClick]);

  return (
    <ButtonStyled onClick={onClickHandler} {...props}>
      {loading ? <Loader width={25} color="white" /> : children}
    </ButtonStyled>
  );
};

Button.propTypes = {
  white: PropTypes.bool,
  black: PropTypes.bool,
  gradient: PropTypes.bool,
  left: PropTypes.bool,
  center: PropTypes.bool,
  right: PropTypes.bool,
  width: PropTypes.number,
  loading: PropTypes.bool,
  to: PropTypes.string,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  white: false,
  black: false,
  gradient: false,
  left: false,
  center: false,
  right: false,
};

export default memo(Button);
