import React, { memo, useState, useCallback } from "react";
import Slider from "@material-ui/core/Slider";
import { VolumeBarStyled, VolumeIcon } from "./style";
import VolumeUpIcon from "icons/volume_up.svg";
import VolumeDownIcon from "icons/volume_down.svg";

const VolumeBar = ({ volume, onChange }) => {
  const [prevVolume, setPrevVolume] = useState(0);

  const onChangeHandler = useCallback((event, value) => {
    onChange(value);
  }, []);

  const onMute = useCallback(() => {
    setPrevVolume(volume);
    onChange(0);
  }, [volume]);

  const onUnMute = useCallback(() => {
    onChange(prevVolume);
  });

  return (
    <VolumeBarStyled>
      {volume === 0 ? (
        <VolumeIcon onClick={onUnMute} src={VolumeDownIcon} alt="Unmute" />
      ) : (
        <VolumeIcon onClick={onMute} src={VolumeUpIcon} alt="Mute" />
      )}
      <Slider
        value={volume}
        onChange={onChangeHandler}
        min={0}
        step={0.01}
        max={1}
        title="Change volume"
      />
    </VolumeBarStyled>
  );
};

export default memo(VolumeBar);
